import React from 'react'
import { inputValidation } from '../../../core/constants/input-validation'
import I18nManager from '../../../core/I18nManager/I18nManager'

const InvalidInputMsg = ({ input, name }) => {
    return (
        <>
            {!input && input !== '' &&
                <small style={{ color: 'red', maxWidth: 400 }}>{inputValidation?.[name]?.[I18nManager.isRTL() ? "ar" : "en"]}</small>
            }
        </>
    )
}

export default InvalidInputMsg
