import images from "./images";
import svgs from "./svgs";

export const constants = {
    departments: [
        {
            titleEn: `Home Services`, titleAr: `الخدمات المنزلية`, imgIcon: svgs.homeIcon,
            img: images.homeServicesDepartment,
            descriptionEn: [`Our personnel are qualified and prepared to deliver the highest level of care and attention to you or your loved ones from the comfort of your own home. From shots to routine checks, we've got you covered.`],
            descriptionAr: [`كوادرنا في الرعاية الصحية المنزلية مدربة ومجهزة لتزويدكم أو أحبائكم بأقصى درجات العناية والاهتمام من المنزل وخدماتنا تختلف من اجراءات الحقن، إلى الفحوص الروتينية المهمة.`],
            list: [
                {
                    titleEn: `Around-the-clock care for all conditions in the comfort of your own home:`, titleAr: `علاج التقرحات السريرية ومعالجة الجروح:`,
                    descriptionEn: [`We have a staff that is completely prepared to give you the service whenever a member of your family needs to visit the doctor for an acute illness or a follow-up on a chronic condition.`],
                    descriptionAr: [`تتطور تقرحات الفراش السريرية عنالدكتوركبار السن بسبب نقص الحركة. لذا يمكن لكادرنا اعداالدكتورنظامًا شاملاً للعناية بالتقرحات السريرية وعلاجها.`]
                },
                {
                    titleEn: `Injecting intravenous fluids:`, titleAr: `الحقن الوريدية:`,
                    descriptionEn: [`Our experts are available to assist you whenever your loved ones need a one-time or routine injection.`],
                    descriptionAr: [`سيكون خبراؤنا متاحين لمساعدتك عنالدكتورحاجة أحالدكتورأحبائك إلى الحقن الوريدية سواء كانت لمرة واحدة أو حقن روتينية.`]
                },
                {
                    titleEn: `Blood testing at home:`, titleAr: `فحص الدم في المنزل:`,
                    descriptionEn: [`Our nurses are here to assist you whenever your loved one has a lab test.`],
                    descriptionAr: [`طاقم الممرضين لدينا هنا لمساعدتك كلما خضع أحالدكتورأفراالدكتورأسرتك لفحص معملي أو مخبري في المنزل، بسرعة وأمان تام.`]
                },
                {
                    titleEn: `Treatment of clinical bedsores and wound dressings:`, titleAr: `رعاية على مدار الساعة لجميع الظروف في راحة منزلك:`,
                    descriptionEn: [`Clinical bedsores develop in the elderly owing to a lack of mobility. Our staff will offer a comprehensive clinical ulcer care regimen.`],
                    descriptionAr: [`لدينا طاقم على استعداالدكتورتام لتقديم الخدمة لك كلما احتاج أحالدكتورأفراالدكتورعائلتك إلى زيارة الطبيب لمرض حاالدكتورأو متابعة حالة مزمنة.`]
                },
                {
                    titleEn: ``, titleAr: ``,
                    descriptionEn: [``],
                    descriptionAr: [``]
                }
            ]
        },
        {
            titleEn: `Laboratory Department`, titleAr: `قسم المختبر`, icon: `flaticon-laboratory`,
            img: images.laboratoryDepartment,
            descriptionEn: [`Our laboratory department's aim is to offer good quality and cost-effective clinical laboratory findings to all of our patients within an acceptable turnaround time.`],
            descriptionAr: [`يهدف قسم المختبر لدينا إلى تقديم نتائج معملية سريرية وتحاليل فحص شاملة ذات جودة عالية وفعالة من حيث التكلفة لجميع مرضانا في غضون فترة زمنية مقبولة.`],
        },
        {
            titleEn: `Obstetrics and Gynecology Clinic`, titleAr: `عيادة النسائية والتوليد`, icon: `flaticon-hospital-bed`,
            img: images.obstetricsAndGynecologyDepartment,
            descriptionEn: [`The FBMC Obstetrics and Gynecology (Ob/Gyn) Department is composed of a team of highly qualified and professional doctors, nurses, and midwives with an optimal mix of specialties to provide the best-personalized care to each patient through all age stages.`],
            descriptionAr: [`يتألف قسم النسائية والتوليد من فريق من الأطباء والممرضات وقابلات التوليد المؤهلين والمتمرسين لتقديم أفضل رعاية شخصية لكل المراجعات من كافة الفئات العمرية.`],
        },
        {
            titleEn: `Pediatrics`, titleAr: `عيادة الأطفال`, icon: `flaticon-ovum`,
            img: images.pediatricsDepartment,
            descriptionEn: [`Please do not hesitate to contact us if your young loved ones require medical attention, as we have a team of highly qualified pediatricians that can assist your child in regaining excellent health.`],
            descriptionAr: [`لا تتردد في زيارتنا إذا كان أحبائك الصغار في حاجة إلى مساعدة طبية، فنحن لدينا مستوصف أطفال وفريق من الأطباء المتخصصين لمساعدة طفلك على العودة إلى صحته السليمة.`],
            list: [
                {
                    titleEn: `Acute and chronic disease treatment: `, titleAr: `توافر اللقاحات اللازمة:`,
                    descriptionEn: [`Children can suffer from both acute and chronic illnesses. Our team is experienced and trained in providing the finest care for children.`],
                    descriptionAr: [`يجب إعطاء اللقاحات بشكل منتظم للأطفال الصغار حتى يعيشوا بشكل طبيعي لذا نوفر جميع اللقاحات لطفلك في عياداتنا علماً اننا سنعلمك أيضًا عندما يحين موعالدكتورالتطعيم.`]
                },
                {
                    titleEn: `Necessary vaccinations availability:`, titleAr: `تتبع نمو الأطفال وتغذيتهم لكل الأعمار:`,
                    descriptionEn: [`Vaccinations should be administered on a regular basis to youngsters in order for them to live properly. We supply all of your child's vaccines in our clinics. We will also notify you when the vaccine is due.`],
                    descriptionAr: [`قم بزيارتنا لتفقالدكتورحالة وتطور طفلك لنوضح لك مراحل تطور الطفل لذا سيقوم موظفونا بمراجعة جميع التفاصيل معك إذا كنت تريالدكتورمعرفة كافة التفاصيل المتعلقة بنمو طفلك وتغذيته.`]
                },
                {
                    titleEn: `Keep track of infants' development and nutrition:`, titleAr: `علاج الأمراض الحادة والمزمنة:`,
                    descriptionEn: [`Come meet us to check how your child is developing. We will show you how your youngster is developing. Our staff will go through all the specifics with you if you want to know how to grow and develop your kid.`],
                    descriptionAr: [`يمكن أن يعاني الأطفال من أمراض حادة ومزمنة. فريقنا من ذوي الخبرة والمدربين في تقديم أرقى رعاية لأطفالكم. اتصل الحين، واحجز موعدك!`]
                },
            ]
        },
        {
            titleEn: `Orthopedic Clinic`, titleAr: `عيادة العظام`, icon: `flaticon-medical-report`,
            img: images.orthopedicClinicDepartment,
            descriptionEn: [`We offer a variety of conservative and progressive care options for bone, tendon, ligament, muscle, and joint disorders, from examination and diagnosis through prevention, treatment, and rehabilitation.`],
            descriptionAr: [`نقدم مجموعة متنوعة من خدمات الرعاية بالاضافة الى أفضل أطباء عظام في الرياض لمعالجة كافة الاضطرابات في العظام والأوتار والأربطة والعضلات والمفاصل، بدءًا من الفحص والتشخيص حتى الوقاية والعلاج وإعادة التأهيل.`],
        },
        {
            titleEn: `Internal Medicine`, titleAr: `عيادة الطب الباطني`, icon: `flaticon-medicine`,
            img: images.internalMedicineDepartment,
            descriptionEn: [`Our Internal Medicine clinic is well-equipped to handle a wide range of acute and chronic diseases, as well as follow-up care.`],
            descriptionAr: [`عيادة الطب الباطني مجهزة تجهيزا كاملاً لمعالجة مجموعة متنوعة من الاضطرابات الحادة والمزمنة المختلفة بما في ذلك متابعة المريض ولأننا ندرك أن المرضى قد يعانون من أعراض متعددة تتعلق بمرضهم فيوجد لدينا أخصائيين باطنية بخبرة طويلة مستعدون لاستقبالكم والاهتمام بكم دائماً.`],
            list: [
                {
                    titleEn: `Asthma treatment and follow-up:`, titleAr: `مراقبة و ضبط ضغط الدم:`,
                    descriptionEn: [`Asthma is a common disease in the Kingdom. It affects people of all ages and genders. This disease may be due to another disease. Therefore, asthma patients can be treated at FBMC with utmost care.`],
                    descriptionAr: [`يعالدكتورارتفاع ضغط الدم من أكثر الاضطرابات انتشارًا وخطورة التي يمكن أن يعاني منها الناس. يُعرف هذا المرض بالقاتل الصامت لأنه قالدكتوريصيب الناس دون أن يسبب أعراضًا لذا يتم إعطاء المرضى الذين يعانون من ارتفاع ضغط الدم دواء لعلاج هذا المرض في مجمع الدكتور فهالدكتوربافقيه الطبي اعتمادًا على تاريخهم الطبي.`]
                },
                {
                    titleEn: `Diabetes management and follow-up:`, titleAr: `التهاب المفاصل الروماتويدي:`,
                    descriptionEn: [`Diabetes may increase the risk of many disorders. It can even start at late ages. The disease has the potential to cause blindness or kidney failure in advanced cases. Therefore, diabetes is treated and controlled within our complex using the latest methods.`],
                    descriptionAr: [`في المملكة العربية السعودية ، تعتبر الأمراض الروماتيزمية من الأمراض الشائعة. فهو مرض يصيب النساء أكثر من الرجال. حيث يمكن أن يؤثر على كل عضو في الجسم ، بما في ذلك المفاصل والجلالدكتوروالكلى. وإذا لم تتم إدارة الحالة بشكل صحيح ، فقالدكتوريؤدي ذلك إلى حدوث إعاقة خطيرة. لذا مجمع الدكتور فهالدكتوربافقيه الطبي، نشخص المرض بدقة ونقدم العلاج المناسب لذلك.`]
                },
                {
                    titleEn: `Rheumatoid arthritis:`, titleAr: `إدارة ومتابعة مرض السكري`,
                    descriptionEn: [`In Saudi Arabia, rheumatic illnesses are a frequent disease. It affects women more than men. This condition can affect every organ in the body, including the joints, skin, and kidneys. If the condition is not managed properly, it might result in serious disability. In FBMC, we accurately diagnose the condition and so provide the right therapy.`],
                    descriptionAr: [`قالدكتوريزيالدكتورمرض السكري من مخاطر الإصابة باضطرابات عديدة. ويمكن حتى أن تبدأ عنالدكتوركبار السن. ولدى المرض القدرة على التسبب في العمى أو الفشل الكلوي في حالات متقدمة. لذا يتم علاج السكري والتحكم فيه داخل مجمعنا باستخدام احدث الأساليب.`]
                },
                {
                    titleEn: `Controlling and monitoring blood pressure:`, titleAr: `علاج الربو ومتابعته:`,
                    descriptionEn: [`High blood pressure is one of the most prevalent and serious disorders that people can suffer from. Because it may infect people without causing symptoms, this illness is known as the silent killer. Blood pressure treatment and management will enhance and extend life. Patients with high blood pressure will be given medicine to treat the ailment at FBMC depending on their medical history.`],
                    descriptionAr: [`الربو مرض منتشر في المملكة. حيث يصيب الناس من جميع الأعمار والأجناس. وقالدكتوريكون هذا المرض بسبب مرض آخر. لذا يمكن علاج مرضى الربو في مجمع الدكتور فهالدكتوربافقيه الطبي بعناية تامة.`]
                },
            ]
        },
        {
            titleEn: `Surgery Clinic`, titleAr: `عيادة الجراحة`, icon: `flaticon-uterus`,
            img: images.surgeryClinicDepartment,
            descriptionEn: [`FBMC is designed to handle minor and non-invasive operations. Visit us if you are suffering from any conditions that necessitate a surgical operation, or if you just want a second opinion.`],
            descriptionAr: [`تم تجهيز مجمع د.فهالدكتوربافقيه الطبي بعيادات جراحية للفحوصات والإجراءات الجراحية البسيطة. قم بزيارتنا إذا كنت تعاني من أي ظرف يتطلب إجراءً جراحيًا، أو لمجرالدكتورالحصول على مشورة طبية على بالدكتورأمهر الاستشاريون في هذا المجال.`],
            list: [
                {
                    titleEn: `Haemorrhoids removal:`, titleAr: `إزالة البواسير:`,
                    descriptionEn: [`Do you suffer from anal problems? don’t hesitate to pay our consultants and surgeons a visit in the event of any issues.`],
                    descriptionAr: [`إذا كنت تعاني من أي مشاكل في الشرج، يمكن لفريق الجراحين الاستشاريين لدينا إزالة البواسير بسرعة ودون ألم.`]
                },
                {
                    titleEn: `Abscess removal:`, titleAr: `إزالة الخراج:`,
                    descriptionEn: [`Abscess removal is a simple and painless procedure. So visit us as soon as possible for safe and sterile removal of the abscess.`],
                    descriptionAr: [`إزالة الخراج إجراء بسيط وغير مؤلم. لذا قم بزيارتنا في أقرب وقت ممكن لإزالة الخراج بطريقة معقمة وآمنة.`]
                },
                {
                    titleEn: `Circumcision:`, titleAr: `الختان:`,
                    descriptionEn: [`Our skilled consultant surgeon will perform the circumcision in a sterile and painless manner.`],
                    descriptionAr: [`سيقوم جراحنا الاستشاري الماهر بإجراء عملية الختان بطريقة معقمة وغير مؤلمة.`]
                }
            ]
        },
        {
            titleEn: `Radiology`, titleAr: `الأشعة`, icon: `flaticon-vitamin`,
            img: images.radiolagyDepartment,
            descriptionEn: [`If you have been injured or have inexplicable pains and sores, our Radiology department is fully prepared and eager to provide you with the answers you need.`],
            descriptionAr: [`إذا كنت تعاني من إصابة أو تعاني من بعض الأوجاع غير المبررة ، فإن قسم الأشعة لدينا مجهز بشكل جيالدكتورومستعالدكتورلإعطائك الإجابات التي تبحث عنها:`],
            list: [
                {
                    titleEn: `Chest x-ray:`, titleAr: `أشعة الصدر:`,
                    descriptionEn: [`When you have respiratory problems and your doctor needs to check your lungs, our x-rays will definitely help.`],
                    descriptionAr: [`عندما تكون لديك مشاكل في الجهاز التنفسي ويتعين على طبيبك فحص رئتيك ، فإن صورنا بالأشعة السينية ستساعالدكتوربكل تأكيد.`]
                },
                {
                    titleEn: `Skeletal x-rays:`, titleAr: `أشعة الهيكل العظمي:`,
                    descriptionEn: [`For fractured bones, our X-ray machine can diagnose the fracture with extreme accuracy and thus treat the fracture in the best way.`],
                    descriptionAr: [`إذا كان لديك كسر في العظام ، فيمكن لجهاز الأشعة السينية لدينا تشخيص الكسر بدقة متناهية وبالتالي معالجة الكسر بأفضل الطرق.`]
                },
                {
                    titleEn: `Ultrasound:`, titleAr: `الموجات فوق الصوتية:`,
                    descriptionEn: [`Whether to take a pregnancy test or examine any internal organ. Our expert staff will always be on hand for a comprehensive body scan.`],
                    descriptionAr: [`سواء لإجراء اختبار الحمل أو فحص أي عضو داخلي. سيكون كادرنا الخبير دائماً على استعداالدكتورلإجراء مسح شامل للجسم.`]
                },
            ]
        },
        {
            titleEn: `Emergency department`, titleAr: `قسم الطوارئ`, icon: `flaticon-surgery-room`,
            img: images.emergencyDepartment,
            descriptionEn: [`The emergency department (ER) is a full-service unit staffed with skilled and knowledgeable employees who deal with various degrees of emergency and urgent care. The emergency department has private examination rooms for general care, a resuscitation unit.`],
            descriptionAr: [`قسم الطوارئ هو قسم متكامل مزوالدكتوربكادر ماهر ومطّلع قادر على التعامل مع درجات مختلفة من الرعاية الطارئة والعاجلة. ويحتوي قسم الطوارئ على غرف فحص خاصة للرعاية العامة بالاضافة الى وحدة إنعاش.`],
        },
    ],
    bundles: [
        // {
        //     bundleNameEn: `Special bundle for Takaful Al-Arabia customers, “The platinum comprehensive examination”`, bundleNameAr: `عرض خاص لعملاء تكافل العربية الفحص الشامل البلاتيني ١٧ تحليل:`, price: `555`,
        //     bundleDetailsEn: [`Vitamin D`, `Vitamin B12`, `Thyroid hormone`, `Full blood picture`, `The liver enzyme (AST)`, `The liver enzyme (ALT)`, `Gout analysis`, `Kidney Functions (creatine)`, `Kidney Functions (urea)`, `Cholesterol analysis`, `Sugar level analysis`, `Calcium`, `Blood type`, `Sedimentation speed`, `Urine analysis`, `ECG`, `Chest x-ray`],
        //     bundleDetailsAr: [`فيتامين د`, `فيتامين ب12`, `هرمون الغدة الدرقية`, `صورة الدم كاملة`, `انزيم الكبد (AST)`, `انزيم الكبد (ALT)`, `تحليل النقرس`, `وظائف كلى (كرياتين)`, `وظائف (يوريا)`, `تحليل الكوليسترول`, `سكر صائم`, `كالسيوم`, `فصيلة الدم`, `سرعة ترسيب`, `تحليل بول`, `تخطيط قلب`, `أشعة صدر`]
        // },
        {
            bundleNameEn: `Child health silver bundle`, bundleNameAr: `باقة اطمئن على صحة طفلك الفضية`, price: `199`,
            bundleDetailsEn: [`Complete blood picture.`, `Vitamin D analysis.`, `Iron level analysis.`, `Calcium analysis.`],
            bundleDetailsAr: [`صورة دم كاملة.`, `تحليل فيتامين د.`, `تحليل مخزون الحديد.`, `تحليل الكالسيوم.`],
        },
        {
            bundleNameEn: `Hair loss analysis bundle`, bundleNameAr: `باقة تحليل تساقط الشعر`, price: `399`,
            bundleDetailsEn: [`Vitamin D.`, `Vitamin B12.`, `Thyroid analysis.`, `Iron levels analysis.`, `Complete blood picture.`, `Zinc analysis.`],
            bundleDetailsAr: [`فيتامين د.`, `فيتامين ب ١٢.`, `تحليل الغدة الدرقية.`, `مخزون الحديد.`, `صورة دم كاملة.`, `تحليل الزنك.`]
        },
        {
            bundleNameEn: `Pregnancy follow-up package:`, bundleNameAr: `باقة متابعة الحمل`, price: `1250`,
            bundleDetailsEn: [`12 Visits`, `Ultrasound sessions`, `HCV/HBS test`, `TOXOPLASMA analysis`, `Blood sugar analysis`, `Urine analysis`, `Blood group analysis`, `Analysis of the German measles virus RUBELLA`, `Complete blood count (CBC)`],
            bundleDetailsAr: [`12 زيارة (كشف)`, `8جلسات أشعة صوتية للجنين`, `تحليل الفايروس الكبدي HCV / HBS`, `تحليل فايروس مرض القطط TOXOPLASMA`, `تحليل السكر بالدم BLOOD SUGAR`, `تحليل البول URINE ANALYSIS`, `تحليل فصيله الدم ABO RH`, `تحليل فايروس الحصبة الألماني RUBELLA`, `تحليل صورة الدم كاملة CBC`]
        },
        {
            bundleNameEn: `Platinum comprehensive examination package`, bundleNameAr: `باقة الفحص الشامل البلاتيني`, price: `349`,
            bundleDetailsEn: [`Free examination`, `Vitamin D`, `VITAMIN B12`, `Thyroid hormone TSH`, `Complete blood count (CBC)`, `Liver enzyme test AST`, `Liver enzyme test ALT`, `Uric acid test`, `Kidney functions Creatinine test`, `Kidney functions Urea test`, `Cholesterol analysis`, `Blood sugar analysis`, `Calcium analysis`, `Blood group analysis`, `blood sedimentation rate ESR`, `Urine analysis`, `ECG`, `Chest Xray`],
            bundleDetailsAr: [`كشف الطبيب مجاناً`, `فيتامين د VIT D`, `فيتامين ب VIT B12`, `هرمون الغدة الدرقيه TSH`, `صورة الدم كاملة CBC`, `انزيم الكبد AST`, `انزيم الكبد ALT`, `تحليل النقرس URIC ACID`, `وظائف الكلى CREATININE`, `وظائف الكلى UREA`, `تحليل الكوليسترول CHOLESTEROL`, `تحليل السكر BLOOD SUGAR`, `تحليل الكالسيوم CALCIUM`, `تحليل فصيلة الدم ABO RH`, `تحليل سرعة ترسيب الدم ESR`, `تحليل البول URINE ANALYSIS`, `تخطيط القلب ECG`, `أشعة الصدر CHEST XRAY`]
        }
    ],
    promotions: [
        // {
        //     titleEn: `Special offer for the Ministry of Health, military sector, ministry of education, and the chamber of commerce employees:`, titleAr: `لمنسوبي وزارة الصحة ومنسوبي القطاع العسكري وقطاع وزارة التربية والتعليم وقطاع الغرفة التجارية:`, price: '0',
        //     descriptionEn: [`40% discount on examinations`, `30% discount on laboratory and radiology services`],
        //     descriptionAr: [`خصم خاص 40% على الكشفيات`, `وخصم 30 % على خدمات المختبر والأشعة`]
        // },
        {
            titleEn: `Get vaccinated and get 20% off on medical checkups.`, titleAr: `خذ اللقاح واحصل على خصم 20% على الكشفيات الطبيه`, discount: '20',
            img: images.offerDoctor,
        },
        {
            titleEn: `Covid-19 test special offer.`, titleAr: `عرض خاص لمسحة الكورونا`, price: '185',
            img: images.offerPcr,
        },
        {
            titleEn: `Child health and condition 3 months analysis.`, titleAr: `متابعة حالة وصحة الطفل لمدة 3 شهور`, price: '199',
            img: images.offerChild,
        },
        {
            titleEn: `Pregnancy test.`, titleAr: `فحص الحمل`, price: '59',
            img: images.offerPregnant,
        },
    ],
    testimonals: [
        {
            titleEn: `Azim Noor`, titleAr: `سمية يونس`,
            descriptionEn: `Thanks to dr Fahed Bafaqih medical complex for the wonderful service. They're very nice, friendly and they help you get better. I highly recommend their services!`,
            descriptionAr: `عندهم طبيبة أطفال رائعة وبرامج متابعة الطفل وتطعيماته متكاملة وعندهم تخصصات طبية للباطنية والسكر والغدد الصماء ومتابعة الحمل، وفي برامج توعوية تثقيفية لجميع التخصصات بكل بساطة مركز أكثر من رائع!`
        },
        {
            titleEn: `Muntasir Alyyan`, titleAr: `هتان مأمون`,
            descriptionEn: `this hospital is very nice and professional, they're friendly and they help you get better. The service is exquisite!`,
            descriptionAr: `شكرا للدكتورة جيهان اخصائية اطفال وحديثي الولادة والمركز نظيف واستقباله محترم ايضاً شكرا لكل القائمين على المركز بأقسامه 🙏`
        },
        {
            titleEn: `Mohammad Ghandoor`, titleAr: `أيسر محمد`,
            descriptionEn: `Excellent reception, excellent treatment, classy, and I’d to thank the laboratory technician, Areej, for her kind and professional treatment`,
            descriptionAr: `قسم اللاطفال من افضل أقسام الاطفال في الرياض عندهم خبره كثير وعن تجربه عندهم ذمه وضمير اخلاقهم عاليه جدا`
        },
        {
            titleEn: `Rojyan Kurdi`, titleAr: `خالد العتيبي`,
            descriptionEn: `I contacted the complex and a girl named Ibtihal I guess answered the call, and she was very polite and professional, so a big THANK YOU`,
            descriptionAr: `مجمع راقي واطباء ممتازين بالأخص طبيبة الأطفال دكتوره جيهان على قدر من العلم ومهنيه عاليه بالتعامل مع الاطفال، وموظفين الاستقبال رائعين ملاحظة: المركز يحتاج فقط لزياده اعلانات لان مكانه داخل الحي`
        },
        {
            titleEn: `Doua Yousef`, titleAr: `علي الفقير`,
            descriptionEn: `Mashallah, calm and cooperative reception and nursing staff 👍👍 A very wonderful and comfortable experience, they deserve all the best`,
            descriptionAr: `خمس نجوم قبل ما نجيكم، اتصلت بالمستشفى قبل اشوي وردت عليا بنت اخلاقها عالية ومؤدبه جداً، وشرحت لي كل شي بتفصيل مع اني كثرت الاسئله عليها، وخلال ثواني ارسلت لي موقع المستشفى على الواتساب، كنت استفسر عن تحليل PCR.`
        },
    ]
}