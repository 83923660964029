import React, { useState } from 'react'
import images from '../../core/constants/images'
import I18nManager from '../../core/I18nManager/I18nManager'
import ViewContainer from '../../core/routes/view-container/ViewContainer'
import { sendApplyJobEmail } from '../../core/api/Api'
import { checkFormErrors, fullNameValidation, emailValidation } from '../../core/common-service/CommonService'
import ReactModal from '../_common/react-modal/ReactModal'
import svgs from '../../core/constants/svgs'
import InvalidInputMsg from '../_common/invalid-input-msg/InvalidInputMsg'

const Careers = () => {

    const [FullName, setFullName] = useState('')
    const [MobileNo, setMobileNo] = useState('')
    const [Email, setEmail] = useState('')
    const [Subject, setSubject] = useState('')
    const [Body, setBody] = useState('')
    const [Attachment, setAttachment] = useState('')
    const [loading, setLoading] = useState(false)
    const [formErrors, setFormErrors] = useState({
        FullName: '',
        MobileNo: '',
        // Email: '',
        // Subject: '',
    })

    const checkFormValues = () => {
        setFormErrors({
            ...formErrors,
            FullName: fullNameValidation(FullName),
            MobileNo: MobileNo.length >= 6 ? true : false,
        })
    }

    const onInputChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        switch (name) {
            case 'FullName':
                setFormErrors({ ...formErrors, FullName: fullNameValidation(value) })
                setFullName(value)
                break;
            case 'MobileNo':
                setFormErrors({ ...formErrors, MobileNo: value.length >= 6 ? true : false })
                setMobileNo(value)
                break;
            case 'Email':
                // setFormErrors({ ...formErrors, Email: emailValidation(value) })
                setEmail(value)
                break;
            case 'Subject':
                // setFormErrors({ ...formErrors, Subject: value.length >= 4 ? true : false })
                setSubject(value)
                break;
            case 'Body':
                setBody(value)
                break;
            default:
                break;
        }
    }

    const onCvUploadClick = () => {
        document.getElementById("cvupload").click();
    }

    const onCvUpload = (e) => {
        let file = e.target.files[0]
        if (file.size > 4194304) {
            setAttachment('')
            setFormErrors({ ...formErrors, Attachment: false })
            alert('File size must be less than 4 MB')
        } else {
            setFormErrors({ ...formErrors, Attachment: true })
            setAttachment(file)
        }
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (!loading && checkFormErrors(formErrors)) {
            setLoading(true)
            let cvFile = document.getElementById("cvupload").files[0];
            let formData = new FormData();
            formData.append("attachment", cvFile);
            const params = { name: FullName, email: Email, mobile: MobileNo, subject: Subject, body: Body }
            sendApplyJobEmail(params, formData).then(res => {
                const el = document.getElementById('contactUsModalButton')
                el.click()
                setLoading(false)
                setFullName(''); setEmail(''); setMobileNo(''); setSubject(''); setBody(''); setAttachment('')
                document.getElementById('ApplyJobFullName').value = ''
                document.getElementById('ApplyJobMobileNo').value = ''
                document.getElementById('ApplyJobEmail').value = ''
                document.getElementById('ApplyJobSubject').value = ''
                document.getElementById('ApplyJobBody').value = ''
                document.getElementById('cvupload').value = ''
            }).catch(e => setLoading(false))
        } else {
            checkFormValues()
        }
    }

    return (
        <ViewContainer>
            <section className="page-title" style={{ backgroundImage: `url(${images[I18nManager.isRTL() ? "headerJobAr" : "headerJobEn"]})` }}>
                <div className="auto-container">
                    <div className="title-outer">
                        <h1>{I18nManager.isRTL() ? "وظائف" : "Careers"}</h1>
                        <ul className="page-breadcrumb">
                            <li><a href="index.html">{I18nManager.isRTL() ? "الصفحة الرئيسية" : "Home"}</a></li>
                            <li>{I18nManager.isRTL() ? "وظائف" : "Careers"}</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="appointment-section-three" style={{ backgroundImage: `url(images/background/2.jpg)` }}>
                <div className="auto-container">
                    <div className="row">
                        <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                            <div className="inner-column">
                                <h3>{I18nManager.isRTL() ? "وظائف" : "Careers"}</h3>
                                <div className="text">{I18nManager.isRTL() ? "نرحب بكل من لديه الخبرة اللازمة والرغبة في الانضمام لكادرنا المتطور باستمرار، من أطباء وممرضين وخبراء بالرياض والمملكة العربية السعودية والعالم" : "We welcome everyone with the experience and desire to join our constantly evolving team, including doctors, nurses, and technicians in Riyadh, Saudi Arabia and the world."}</div>
                            </div>
                        </div>

                        <div className="form-column col-lg-6 col-md-12 col-sm-12">
                            <div className="inner-column">
                                <div className="appointment-form default-form">
                                    <form>
                                        <div className="form-group">
                                            <div className="response"></div>
                                        </div>
                                        <div className="form-group">
                                            <input type="text" id="ApplyJobFullName" name="FullName" onChange={onInputChange} className="username" placeholder={(I18nManager.isRTL() ? "الاسم الكامل" : "Full Name") + " *"} />
                                            <InvalidInputMsg input={formErrors.FullName} name='FullName' />
                                        </div>
                                        <div className="form-group">
                                            <input type="tel" id="ApplyJobMobileNo" name="MobileNo" onChange={onInputChange} className="username" placeholder={(I18nManager.isRTL() ? "رقم الهاتف" : "Telephone number") + " *"} />
                                            <InvalidInputMsg input={formErrors.MobileNo} name='MobileNo' />
                                        </div>
                                        <div className="form-group">
                                            <input type="email" id="ApplyJobEmail" name="Email" onChange={onInputChange} className="email" placeholder={I18nManager.isRTL() ? "البريد الالكتروني " : "Email Address"} />
                                            {/* <InvalidInputMsg input={formErrors.Email} name='Email' /> */}
                                        </div>
                                        <div className="form-group">
                                            <input type="text" id="ApplyJobSubject" name="Subject" onChange={onInputChange} placeholder={I18nManager.isRTL() ? "التخصص" : "Specialization"} />
                                            {/* <InvalidInputMsg input={formErrors.Subject} name='Subject' /> */}
                                        </div>
                                        <div className="form-group">
                                            <textarea id="ApplyJobBody" name="Body" onChange={onInputChange} placeholder={I18nManager.isRTL() ? "نبذة عنك" : "Abou you"}></textarea>
                                        </div>
                                        <div className="form-group d-flex flex-column align-items-center" onClick={onCvUploadClick}>
                                            <div className="theme-btn btn-style-two">
                                                <span className="btn-title">{I18nManager.isRTL() ? "تحميل السيرة الذاتية" : "Upload CV"}</span>
                                            </div>
                                            <input type="file" multiple={false} onChange={onCvUpload} accept=".doc,.docx, .pdf" id="cvupload" name="attachment" className="d-none" />
                                        </div>
                                        <div className="form-group">
                                            <button onClick={onSubmit} className="theme-btn btn-style-one" type="button" name="submit-form"><span className="btn-title">{I18nManager.isRTL() ? "ارسال الطلب" : "Send request"}</span></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <button style={{ display: 'none' }} id="careersCenterModalButton" type="button" data-toggle="modal" data-target="#careersCenterModal" />
            <ReactModal id="careersCenterModal" body={
                <div className="d-flex flex-column justify-content-center align-items-center" style={{ padding: 40 }}>
                    <button type="button" data-dismiss="modal" aria-label="Close" style={{ position: 'absolute', right: 40, top: 20 }}>
                        <i className="fas fa-times"></i>
                    </button>
                    <img loading="lazy" width="70" height="70" src={svgs.congratulationCheck} title={svgs.congratulationCheck?.split('/')?.pop()} alt={svgs.congratulationCheck?.split('/')?.pop()} />
                    <h3 style={{ marginTop: '0.5rem' }}>{I18nManager.isRTL() ? "تهانينا!" : "Congratulations!"}</h3>
                    <span>{I18nManager.isRTL() ? "سوف نتواصل معك في أقرب وقت ممكن." : "We will contact with you as soon as possible."}</span>
                    <button className="theme-btn btn-style-one mt-5" data-dismiss="modal" aria-label="Close" type="button" id="submit" name="submit-form"><span className="btn-title">{I18nManager.isRTL() ? "أغلق" : "Close"}</span></button>
                </div>
            } />

        </ViewContainer>
    )
}
export default Careers
