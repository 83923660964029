import React, { useState } from 'react';
import './ReadMoreText.css';
import I18nManager from '../../../core/I18nManager/I18nManager';

const ReadMoreText = ({ text, className, onClick }) => {
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <p className={className} style={{ display: 'inline', width: '100%' }} onClick={onClick}>
            {isReadMore ? text.slice(0, 140) : text}
            {isReadMore ? '...' : ''}&nbsp;
            <span onClick={toggleReadMore} className="read-more-anchor">
                {isReadMore ? (I18nManager.isRTL() ? "اقرأ أكثر" : "Read more") : (I18nManager.isRTL() ? "أقرأ أقل" : "Read less")}
            </span>
        </p>
    );
}

export default ReadMoreText;