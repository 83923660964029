import React from 'react'
import './ViewDisplay.css'

const ViewDisplay = ({ desktopView, mobileView }) => {
    return (
        <>
            <div className="show-display-min-md hide-display-max-md">
                {desktopView}
            </div>
            <div className="hide-display-min-md show-display-max-md">
                {mobileView}
            </div>
        </>
    )
}
export default ViewDisplay