import React, { useState } from 'react'
import { constants } from '../../core/constants/constants'
import images from '../../core/constants/images'
import I18nManager from '../../core/I18nManager/I18nManager'
import ViewContainer from '../../core/routes/view-container/ViewContainer'
import FeatureBackView from '../home/feature-back-view/FeatureBackView'
import FlipCard from '../_common/flip-card/FlipCard'

const PromotionsBundles = () => {

    const [promotions, setPromotions] = useState(constants.promotions)
    const [bundles, setBundles] = useState(constants.bundles)

    const onFlipPromotionCard = (index) => (e) => {
        e.preventDefault()
        let tmpData = [...promotions]
        tmpData[index].isFlipped = !tmpData[index].isFlipped
        setPromotions(tmpData)
    }

    const onFlipBundleCard = (index) => (e) => {
        e.preventDefault()
        let tmpData = [...bundles]
        tmpData[index].isFlipped = !tmpData[index].isFlipped
        setBundles(tmpData)
    }

    return (
        <ViewContainer>
            <section className="page-title" style={{ backgroundImage: `url(${images[I18nManager.isRTL() ? "headerOfferAr" : "headerOfferEn"]})` }}>
                <div className="auto-container">
                    <div className="title-outer">
                        <h1>{I18nManager.isRTL() ? "العروض والباقات" : "Promotions and Bundles"}</h1>
                        <ul className="page-breadcrumb">
                            <li><a href="index.html">{I18nManager.isRTL() ? "الصفحة الرئيسية" : "Home"}</a></li>
                            <li>{I18nManager.isRTL() ? "العروض والباقات" : "Promotions and Bundles"}</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="pricing-section">
                <div className="auto-container">
                    <div className="sec-title text-center">
                        <span className="sub-title">{I18nManager.isRTL() ? "الباقات" : "Bundles"}</span>
                        <h2>{I18nManager.isRTL() ? "تفاصيل الباقات" : "Bundles Details"}</h2>
                        <span className="divider"></span>
                    </div>
                    <div className="outer-box">
                        <div className="row">
                            {bundles.map((item, index) => (
                                <div className="col-lg-3 col-md-6 col-12" key={index}>
                                    <FlipCard isFlipped={item?.isFlipped}
                                        containerClassName="pricing-block"
                                        frontChildren={
                                            <div className="inner-box h-100" onClick={onFlipBundleCard(index)}>
                                                <div>
                                                    <div className="price-box">
                                                        <h4 className="price">{item?.price} {I18nManager.isRTL() ? "ريال" : "SAR"}</h4>
                                                        {/* <div className="validaty">Per Month</div> */}
                                                    </div>
                                                    <h3 className="title">{item?.[I18nManager.isRTL() ? "bundleNameAr" : "bundleNameEn"]}</h3>
                                                    <ul className="features">
                                                        {item?.[I18nManager.isRTL() ? "bundleDetailsAr" : "bundleDetailsEn"]?.map((el, index) => (
                                                            <li key={index}>{el}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <div className="btn-box">
                                                    <a href="/#" className="theme-btn">{I18nManager.isRTL() ? "احجز الحين" : "Get the bundle"}</a>
                                                </div>
                                            </div>
                                        }
                                        backChildrem={
                                            <div className="inner-box d-flex flex-column justify-content-between p-5">
                                                <div onClick={onFlipBundleCard(index)} style={{ position: 'absolute', cursor: 'pointer', zIndex: 10, top: 10, right: 15 }}>
                                                    <i className="fas fa-times"></i>
                                                </div>
                                                <FeatureBackView index={index} item={item} />
                                            </div>
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            <section className="services-section-five">
                <div className="auto-container">
                    <div className="sec-title text-center">
                        <span className="sub-title">{I18nManager.isRTL() ? "عروضنا" : "Our Offers"}</span>
                        <h2>{I18nManager.isRTL() ? "أفضل العروض" : "What We Offer"}</h2>
                        <span className="divider"></span>
                    </div>
                    <div className="row">
                        {promotions.map((item, index) => (
                            <div className="col-md-4 col-12" key={index}>
                                <FlipCard key={index} isFlipped={item?.isFlipped}
                                    containerClassName="service-block-five"
                                    frontChildren={
                                        <div className="shop-item">
                                            <div className="inner-box">
                                                <div className="image-box" onClick={onFlipPromotionCard(index)}>
                                                    <figure className="image"><a href="department-detail.html"><img src={item?.img} alt="fbmc offer pcr" title="fbmc offer pcr" /></a></figure>
                                                    <span className="onsale">{I18nManager.isRTL() ? "عرض" : "Sale"}</span>
                                                </div>
                                                <div className="lower-content">
                                                    <div onClick={onFlipPromotionCard(index)}>
                                                        <h4 className="name"><a href="shop-single.html">{item[I18nManager.isRTL() ? "titleAr" : "titleEn"]}</a></h4>
                                                        {item?.price && <div className="price">{item.price} {I18nManager.isRTL() ? "ريال" : "SAR"}</div>}
                                                        {item?.discount && <div className="price">{item.discount} %</div>}
                                                        {item[I18nManager.isRTL() ? "descriptionAr" : "descriptionEn"] &&
                                                            item[I18nManager.isRTL() ? "descriptionAr" : "descriptionEn"].map((el, i) => (
                                                                <p key={i}>{el}</p>
                                                            ))}
                                                    </div>
                                                    <div className="d-flex" style={{ marginTop: 10, }}>
                                                        <a style={{ whiteSpace: 'nowrap', color: '#66C18F', backgroundColor: '#ffffff', boxShadow: `0 0 10px rgb(0 0 0 / 10%)` }} href="/#"
                                                            onClick={onFlipPromotionCard(index)}
                                                            className="theme-btn add-to-cart">{I18nManager.isRTL() ? "احجز الحين" : "Book now"}</a>&nbsp;&nbsp;&nbsp;
                                                        <a style={{ whiteSpace: 'nowrap', color: '#ffffff', backgroundColor: '#66C18F', boxShadow: `0 0 10px rgb(0 0 0 / 10%)` }} href="tel:966556189216"
                                                            className="theme-btn add-to-cart"><i className={"flaticon-phone-1 " + (I18nManager.isRTL() ? "ml-1" : "mr-1")}></i>{I18nManager.isRTL() ? "اتصل الحين" : "Call now"}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    backChildrem={
                                        <div className="inner-box d-flex flex-column justify-content-between p-5">
                                            <div onClick={onFlipPromotionCard(index)} style={{ position: 'absolute', cursor: 'pointer', zIndex: 10, top: 10, right: 15 }}>
                                                <i className="fas fa-times"></i>
                                            </div>
                                            <FeatureBackView index={index} item={item} />
                                        </div>
                                    }
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </section>

        </ViewContainer>
    )
}

export default PromotionsBundles