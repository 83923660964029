import React, { Fragment } from 'react'
import MainNavBar from '../navbar/main-navbar/MainNavBar'
import Footer from '../footer/Footer'

const ViewContainer = ({ children }) => {
    const scrollToTop = (e) => {
        e.preventDefault();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    return (
        <Fragment>
            <MainNavBar />
            {children}
            <Footer />
            <div className="scroll-to-top scroll-to-target" data-target="html" onClick={scrollToTop}>
                <span className="fa fa-angle-up"></span>
            </div>
        </Fragment>
    )
}

export default ViewContainer