import React, { Fragment } from 'react'
import './Footer.css'
import { constants } from '../../constants/constants';
import images from '../../constants/images';
import svgs from '../../constants/svgs';
import I18nManager from '../../I18nManager/I18nManager';
import { useHistory } from 'react-router-dom';

const Footer = () => {
    let history = useHistory()

    const goToPage = (path) => (e) => {
        e.preventDefault();
        history.push(path)
    }

    return (
        <footer className="main-footer">
            <div className="widgets-section" style={{ backgroundImage: `url(${images.fbmcFooterBg})` }}>
                <div className="auto-container">
                    <div className="row">
                        <div className="big-column col-xl-6 col-lg-12 col-md-12 col-sm-12">
                            <div className="row">
                                <div className="footer-column col-xl-7 col-lg-6 col-md-6 col-sm-12">
                                    <div className="footer-widget about-widget">
                                        <div className="logo">
                                            <a href="index.html"><img src={svgs.fbmcLogoFooter} alt="fbmc logo" title="fbmc logo" width="291" height="68" /></a>
                                        </div>
                                        <div className="text">
                                            <p>{I18nManager.isRTL() ? "نوفر لكم في مجمع  الدكتور فهد بافقية الطبي خدمات متكاملة، حتى يتاح لكل شخص فرصة الحصول على خدمات طبية مميزة وبجودة عالية للمحافظة على نمط حياة صحي للجميع." :
                                                "We provide full medical services at FBMC, so every person could have the opportunity to receive qualitative medical help for a healthier lifestyle."}</p>
                                        </div>
                                        <div className="text">
                                            <p style={{ fontWeight: 'bold', marginBottom: 0 }}>{I18nManager.isRTL() ? "شركاء التسويق" : "Marketing Partners"}</p>
                                            <a href="https://docnow247.com" target="_blank" rel="noreferrer" ><img src={images.docnowLogo} alt="docnow logo" title="docnow logo" width="170" height="170" /></a>
                                        </div>
                                        <ul className="social-icon-three">
                                            <li><a href="https://www.facebook.com/Fbmcsa/" target="_blank" rel="noreferrer"><span className="fab fa-facebook-f"></span></a></li>
                                            <li><a href="https://twitter.com/FbmcSa" target="_blank" rel="noreferrer"><span className="fab fa-twitter"></span></a></li>
                                            <li><a href="https://www.instagram.com/Fbmc.Sa/" target="_blank" rel="noreferrer"><span className="fab fa-instagram"></span></a></li>
                                            <li><a href="https://www.youtube.com/channel/UCpyFdu5FBnAGcTSu6qPpFoQ" target="_blank" rel="noreferrer"><span className="fab fa-youtube"></span></a></li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="footer-column col-xl-5 col-lg-6 col-md-6 col-sm-12">
                                    <div className="footer-widget">
                                        <h2 className="widget-title">{I18nManager.isRTL() ? "أهم الصفحات" : "Important pages"}</h2>
                                        <ul className="user-links">
                                            <li><a href="/promotions-bundles" onClick={goToPage('/promotions-bundles')}>{I18nManager.isRTL() ? "العروض" : "Offers"}</a></li>
                                            <li><a href="/promotions-bundles" onClick={goToPage('/promotions-bundles')}>{I18nManager.isRTL() ? "الباقات" : "Bundles"}</a></li>
                                            <li><a href="/contact-us" onClick={goToPage('/contact-us')}>{I18nManager.isRTL() ? "تواصل معنا" : "Contact us"}</a></li>
                                            <li><a href="/about-us" onClick={goToPage('/about-us')}>{I18nManager.isRTL() ? "معلوماتنا" : "About us"}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="big-column col-xl-6 col-lg-12 col-md-12 col-sm-12">
                            <div className="row">
                                <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                                    <div className="footer-widget recent-posts">
                                        <h2 className="widget-title">{I18nManager.isRTL() ? "الأقسام والتخصصات" : "Departments"}</h2>
                                        <ul className="user-links">
                                            {constants.departments.map((item, index) => (
                                                <Fragment key={index}>
                                                    {item?.[I18nManager.isRTL() ? "titleAr" : "titleEn"] && <li key={index}><a href="/#">{item?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]}</a></li>}
                                                </Fragment>
                                            ))}
                                        </ul>
                                    </div>
                                </div>

                                <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                                    <div className="footer-widget contact-widget">
                                        <h2 className="widget-title">{I18nManager.isRTL() ? "للتواصل معنا" : "Contact Us"}</h2>
                                        <div className="widget-content">
                                            <ul className="contact-list">
                                                <li>
                                                    <span className="icon flaticon-placeholder"></span>
                                                    <div className="text">{I18nManager.isRTL() ? "الساهرية، حى الورود،" : "As Sahriyah, Al Wurud,"}<br />{I18nManager.isRTL() ? "الرياض" : "Riyadh"}</div>
                                                </li>

                                                <li>
                                                    <span className="icon flaticon-call-1"></span>
                                                    <div className="text">{I18nManager.isRTL() ? "السبت - الخميس 08:30 ص 12:00 ص" : "Sat - Thu 08:30 AM 12:00 AM"}</div>
                                                    <a href="tel:966556189216"><strong>0556189216</strong></a>
                                                </li>

                                                <li>
                                                    <span className="icon flaticon-email"></span>
                                                    <div className="text">Do you have a Question?<br />
                                                        <a href="mailto:info@fbmc.sa"><strong>info@fbmc.sa</strong></a></div>
                                                </li>

                                                <li>
                                                    <span className="icon flaticon-back-in-time"></span>
                                                    <div className="text">{I18nManager.isRTL() ? "السبت - الخميس" : "Sat - Thu"}
                                                        <br /><strong>{I18nManager.isRTL() ? "08:30 ص 12:00 ص" : "08:30 AM 12:00 AM"}</strong>
                                                    </div>
                                                </li>

                                                <li>
                                                    <iframe title="Maroof" src="https://maroof.sa/Business/GetStamp?bid=172705" style={{ height: 150 }} frameBorder="0" seamless='seamless' scrollable="no"></iframe>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="footer-bottom">
                <div className="auto-container">
                    <div className="inner-container clearfix">
                        <div className="footer-nav">
                            <ul className="clearfix">
                                <li><a href="index.html">{I18nManager.isRTL() ? "سياسة الخصوصية" : "Privacy Policy"}</a></li>
                                <li><a href="/contact-us" onClick={goToPage('/contact-us')}>{I18nManager.isRTL() ? "تواصل معنا" : "Contact us"}</a></li>
                            </ul>
                        </div>

                        <div className="copyright-text">
                            <p>{I18nManager.isRTL() ? "حقوق النشر" : "Copyright"} © {new Date().getFullYear()} <a href="/#">{I18nManager.isRTL() ? "مجمع د. فهد بفاقيه" : "FBMC"} </a>{I18nManager.isRTL() ? "كل الحقوق محفوظة" : "All Rights Reserved"}</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer