import React, { useState } from 'react'
import { sendContactUsEmail } from '../../core/api/Api'
import { checkFormErrors, fullNameValidation, emailValidation } from '../../core/common-service/CommonService'
import images from '../../core/constants/images'
import svgs from '../../core/constants/svgs'
import I18nManager from '../../core/I18nManager/I18nManager'
import ViewContainer from '../../core/routes/view-container/ViewContainer'
import InvalidInputMsg from '../_common/invalid-input-msg/InvalidInputMsg'
import ReactModal from '../_common/react-modal/ReactModal'

const ContactUs = () => {

    const [FullName, setFullName] = useState('')
    const [MobileNo, setMobileNo] = useState('')
    const [Email, setEmail] = useState('')
    const [Body, setBody] = useState('')
    const [loading, setLoading] = useState(false)
    const [formErrors, setFormErrors] = useState({
        FullName: '',
        MobileNo: '',
        // Email: '',
        // Body: ''
    })

    const checkFormValues = () => {
        setFormErrors({
            ...formErrors,
            FullName: fullNameValidation(FullName),
            MobileNo: MobileNo.length >= 6 ? true : false,
        })
    }

    const onInputChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        switch (name) {
            case 'FullName':
                setFormErrors({ ...formErrors, FullName: fullNameValidation(value) })
                setFullName(value)
                break;
            case 'MobileNo':
                setFormErrors({ ...formErrors, MobileNo: value.length >= 6 ? true : false })
                setMobileNo(value)
                break;
            case 'Email':
                // setFormErrors({ ...formErrors, Email: emailValidation(value) })
                setEmail(value)
                break;
            case 'Body':
                // setFormErrors({ ...formErrors, Body: value.length >= 2 ? true : false })
                setBody(value)
                break;
            default:
                break;
        }
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (!loading && checkFormErrors(formErrors)) {
            setLoading(true)
            sendContactUsEmail({ name: FullName, mobile: MobileNo, email: Email, subject: 'Contact Us', body: Body }).then(res => {
                const el = document.getElementById('contactUsModalButton')
                el.click()
                setLoading(false)
                document.getElementById('ContactUsFullName').value = ''
                document.getElementById('ContactUsMobileNo').value = ''
                document.getElementById('ContactUsEmail').value = ''
                document.getElementById('ContactUsBody').value = ''
                setFullName(''); setMobileNo(''); setEmail(''); setBody('');
            }).catch(e => { setLoading(false); })
        } else {
            checkFormValues()
        }
    }

    return (
        <ViewContainer>
            <section className="page-title" style={{ backgroundImage: `url(${images[I18nManager.isRTL() ? "headerContactUsAr" : "headerContactUsEn"]})` }}>
                <div className="auto-container">
                    <div className="title-outer">
                        <h1>{I18nManager.isRTL() ? "تواصل معنا" : "Contact Us"}</h1>
                        <ul className="page-breadcrumb">
                            <li><a href="index.html">{I18nManager.isRTL() ? "الصفحة الرئيسية" : "Home"}</a></li>
                            <li>{I18nManager.isRTL() ? "تواصل معنا" : "Contact"}</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="contact-section" id="contact">
                <div className="small-container">
                    <div className="sec-title text-center">
                        <span className="sub-title">{I18nManager.isRTL() ? `فريقنا المختص جاهز لتلقي استفساراتكم ومكالماتكم على مدار الساعة! ويمكنكم حجز مواعيدكم بأي وقت عن طريق التواصل معنا على المعلومات التالية` :
                            "Our team is ready to receive your inquiries and calls around the clock! You can book your appointments at any time by contacting us via the following information"}</span>
                        <h2>{I18nManager.isRTL() ? "تواصلوا معنا!" : "Contact us!"}</h2>
                        <span className="divider"></span>
                    </div>

                    <div className="contact-box">
                        <div className="row">
                            <div className="contact-info-block col-lg-4 col-md-6 col-sm-12">
                                <div className="inner">
                                    <span className="icon flaticon-worldwide"></span>
                                    <h4><strong>{I18nManager.isRTL() ? "الموقع" : "Address"}</strong></h4>
                                    <a href="https://goo.gl/maps/5GGVfPNVFeyEbUco9" target="_blank" rel="noreferrer"><p>{I18nManager.isRTL() ? "الساهرية، حى الورود،" : "Al-Sahria, Al-Wurud District,"}<br />{I18nManager.isRTL() ? "الرياض" : "Riyadh"}</p></a>
                                </div>
                            </div>

                            <div className="contact-info-block col-lg-4 col-md-6 col-sm-12">
                                <div className="inner">
                                    <span className="icon flaticon-phone"></span>
                                    <h4><strong>{I18nManager.isRTL() ? "أرقامنا" : "Our numbers"}</strong></h4>
                                    <p><a href="tel:966920025909">920025909</a></p>
                                    <p><a href="tel:966556189216">0556189216</a></p>
                                </div>
                            </div>

                            <div className="contact-info-block col-lg-4 col-md-6 col-sm-12">
                                <div className="inner">
                                    <span className="icon flaticon-clock"></span>
                                    <h4><strong>{I18nManager.isRTL() ? "ساعات العمل" : "Working hours"}</strong></h4>
                                    <p>{I18nManager.isRTL() ? "من السبت إلى الجمعة" : "Saturday to Friday"}</p>
                                    <p>{I18nManager.isRTL() ? "من 1:00 م إلى 10:00 م" : "From 1:00 PM to 10:00 PM"}</p>
                                    <p>{I18nManager.isRTL() ? "قسم الطوارئ 24 ساعة" : "Emergency department 24 hours"}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-box">
                        <div className="contact-form">
                            <form action="#" method="post">
                                <div className="row">
                                    <div className="form-group col-lg-12">
                                        <div className="response"></div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <input type="text" id="ContactUsFullName" name="FullName" onChange={onInputChange} className="username" placeholder={(I18nManager.isRTL() ? "الاسم الكامل" : "Full Name") + " *"} />
                                            <InvalidInputMsg input={formErrors.FullName} name='FullName' />
                                        </div>
                                        <div className="form-group">
                                            <input type="tel" id="ContactUsMobileNo" name="MobileNo" onChange={onInputChange} className="username" placeholder={(I18nManager.isRTL() ? "رقم الهاتف" : "Telephone number") + " *"} />
                                            <InvalidInputMsg input={formErrors.MobileNo} name='MobileNo' />
                                        </div>
                                        <div className="form-group">
                                            <input type="email" id="ContactUsEmail" name="Email" onChange={onInputChange} className="email" placeholder={I18nManager.isRTL() ? "البريد الالكتروني " : "Email Address"} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <textarea id="ContactUsBody" name="Body" onChange={onInputChange} className="message" placeholder={I18nManager.isRTL() ? "الوصف" : "Description"}></textarea>
                                        </div>
                                    </div>
                                    <div className="form-group col-lg-12 text-center pt-3">
                                        <button className="theme-btn btn-style-one" onClick={onSubmit} type="button" id="submit" name="submit-form"><span className="btn-title">{I18nManager.isRTL() ? "أرسال" : "Send"}</span></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <section className="map-section">
                <div className="auto-container">
                    <div className="map-outer d-flex justify-content-center">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14496.379814387194!2d46.670762!3d24.7236202!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe64abebc37989851!2z2YXYrNmF2Lkg2K8uINmB2YfYryDYqNin2YHZgtmK2Ycg2KfZhNi32KjZiiAvINil2LTYsdin2YLYqSDYp9mE2KXYqNiq2LPYp9mF2KkgMg!5e0!3m2!1sen!2ssa!4v1642600283929!5m2!1sen!2ssa"
                            style={{ width: '100%', height: 400, border: 0 }} allowFullScreen="" loading="lazy" title="map"></iframe>
                    </div>
                </div>
            </section>
            <button style={{ display: 'none' }} id="contactUsModalButton" type="button" data-toggle="modal" data-target="#contactUsModal" />
            <ReactModal id="contactUsModal" body={
                <div className="d-flex flex-column justify-content-center align-items-center" style={{ padding: 40 }}>
                    <button type="button" data-dismiss="modal" aria-label="Close" style={{ position: 'absolute', right: 40, top: 20 }}>
                        <i className="fas fa-times"></i>
                    </button>
                    <img loading="lazy" width="70" height="70" src={svgs.congratulationCheck} title={svgs.congratulationCheck?.split('/')?.pop()} alt={svgs.congratulationCheck?.split('/')?.pop()} />
                    <h3 style={{ marginTop: '0.5rem' }}>{I18nManager.isRTL() ? "تهانينا!" : "Congratulations!"}</h3>
                    <span>{I18nManager.isRTL() ? "سوف نتواصل معك في أقرب وقت ممكن." : "We will contact with you as soon as possible."}</span>
                    <button className="theme-btn btn-style-one mt-5" data-dismiss="modal" aria-label="Close" type="button" id="submit" name="submit-form"><span className="btn-title">{I18nManager.isRTL() ? "أغلق" : "Close"}</span></button>
                </div>
            } />
        </ViewContainer>
    )
}
export default ContactUs
