import React, { useEffect } from 'react'
import './App.css';
import { Route, useHistory, useLocation, Switch, BrowserRouter } from 'react-router-dom';
import Home from './components/home/Home';
import ContactUs from './components/contact-us/ContactUs';
import Departments from './components/departments/Departments';
import AboutUs from './components/about-us/AboutUs';
import PromotionsBundles from './components/promotions-bundles/PromotionsBundles';
import NotFound from './components/not-found/NotFound';
import Careers from './components/careers/Careers';
import I18nManager from './core/I18nManager/I18nManager';
import DepartmentDetails from './components/departments/department-details/DepartmentDetails';
import HomePopup from './components/_common/home-popup/HomePopup';

const ScrollToTop = () => {
  let history = useHistory()
  const { pathname } = useLocation();

  const loadScript = (src) => {
    var script = document.createElement('script')
    script.setAttribute('src', src)
    script.setAttribute('async', true)
    script.setAttribute('type', 'text/javascript')
    document.body.appendChild(script)
  }

  useEffect(() => {
    const scripts = [
      "/js/jquery.js",
      "/js/popper.min.js",
      "/plugins/revolution/js/jquery.themepunch.revolution.min.js",
      "/plugins/revolution/js/jquery.themepunch.tools.min.js",
      "/plugins/revolution/js/extensions/revolution.extension.actions.min.js",
      "/plugins/revolution/js/extensions/revolution.extension.carousel.min.js",
      "/plugins/revolution/js/extensions/revolution.extension.kenburn.min.js",
      "/plugins/revolution/js/extensions/revolution.extension.layeranimation.min.js",
      "/plugins/revolution/js/extensions/revolution.extension.migration.min.js",
      "/plugins/revolution/js/extensions/revolution.extension.navigation.min.js",
      "/plugins/revolution/js/extensions/revolution.extension.parallax.min.js",
      "/plugins/revolution/js/extensions/revolution.extension.slideanims.min.js",
      "/plugins/revolution/js/extensions/revolution.extension.video.min.js",
      "/js/main-slider-script.js",
      "/js/bootstrap.min.js",
      "/js/jquery.fancybox.js",
      "/js/mmenu.polyfills.js",
      "/js/jquery.modal.min.js",
      "/js/mmenu.js",
      "/js/appear.js",
      "/js/owl.js",
      "/js/wow.js",
      "/js/script.js",
    ]
    scripts.forEach(el => {
      loadScript(el)
    });
  }, [pathname])

  useEffect(() => {
    const hash = window.location.hash
    // check URL prefix
    if ((window.location.pathname.startsWith("/en") && I18nManager.isRTL()) ||
      (window.location.pathname.startsWith("/ar") && !I18nManager.isRTL())) {
      const path = window.location.pathname.split('/')
      let result = '/'
      path.forEach((element, i) => {
        result += (i > 1) ? (element + (i < path?.length - 1 ? '/' : '')) : ''
      });
      history.push(result)
      if (hash) {
        window.location.href = result.split('/').pop() + hash
      }
    } else {
      history.push(pathname)
    }
  }, [history])

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.location.pathname === '/') {
      // in case url does not start with basename
      history.push('/home')
    }
    if (window.location.hash) {
      setTimeout(() => {
        window.location.href = pathname.split('/').pop() + window.location.hash
      }, 500);
    }
    if (sessionStorage.getItem('anchor')) {
      setTimeout(() => {
        window.location.href = pathname.split('/').pop() + '#' + sessionStorage.getItem('anchor')
        sessionStorage.removeItem('anchor')
      }, 500);
    }
  }, [pathname, history]);

  return null;
}

const App = () => {

  return (
    <BrowserRouter basename={I18nManager.isRTL() ? "/ar" : "/en"}>
      <ScrollToTop />
      <HomePopup />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/about-us" component={AboutUs} />
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/departments" component={Departments} />
        <Route exact path="/department/:id" component={DepartmentDetails} />
        <Route exact path="/promotions-bundles" component={PromotionsBundles} />
        <Route exact path="/careers" component={Careers} />
        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
