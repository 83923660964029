import React from 'react'
import { constants } from '../../core/constants/constants'
import images from '../../core/constants/images'
import I18nManager from '../../core/I18nManager/I18nManager'
import ViewContainer from '../../core/routes/view-container/ViewContainer'

const Departments = ({ history }) => {

    const goToPage = (i) => (e) => {
        e.preventDefault()
        history.push('/department/' + i)
    }

    return (
        <ViewContainer>
            <section className="page-title" style={{ backgroundImage: `url(${images[I18nManager.isRTL() ? "headerDepartmentAr" : "headerDepartmentEn"]})` }}>
                <div className="auto-container">
                    <div className="title-outer">
                        <h1>{I18nManager.isRTL() ? "أقسامنا" : "Departments"}</h1>
                        <ul className="page-breadcrumb">
                            <li><a href="index.html">{I18nManager.isRTL() ? "الصفحة الرئيسية" : "Home"}</a></li>
                            <li>{I18nManager.isRTL() ? "أقسامنا" : "Departments"}</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="services-section-two">
                <div className="auto-container">
                    <div className="carousel-outer">
                        <div className="row">
                            {constants.departments.map((item, index) => (
                                <div key={index} className="service-block-two col-lg-4 col-md-6 col-sm-12" onClick={goToPage(index)}>
                                    <div className="inner-box">
                                        <div className="image-box">
                                            <figure className="image"><a href="department-detail.html"><img src={item?.img} alt="" /></a></figure>
                                        </div>
                                        <div className="lower-content">
                                            <div className="title-box">
                                                {item?.icon && <span className={"icon " + item?.icon}></span>}
                                                {item?.imgIcon && <span className="icon">
                                                    <img width="50" height="50" src={item?.imgIcon} alt="home" title="home" />
                                                </span>}
                                                <h4><a href="department-detail.html">{item[I18nManager.isRTL() ? "titleAr" : "titleEn"]}</a></h4>
                                            </div>
                                            {item[I18nManager.isRTL() ? "descriptionAr" : "descriptionEn"] &&
                                                item[I18nManager.isRTL() ? "descriptionAr" : "descriptionEn"].map((el, i) => (
                                                    <div className="text" key={i}>{(el.slice(0, 130)).trim()}...</div>
                                                ))
                                            }
                                            <span className={"icon-right " + item.icon}></span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </ViewContainer>
    )
}

export default Departments
