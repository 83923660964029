import React, { Fragment } from 'react'
import ReactCardFlip from 'react-card-flip';

const FlipCard = ({ containerClassName, isFlipped, frontChildren, backChildrem }) => {
    return (
        <ReactCardFlip containerClassName={containerClassName} isFlipped={isFlipped} flipDirection='horizontal'>
            <Fragment>
                {frontChildren}
            </Fragment>
            <Fragment>
                {backChildrem}
            </Fragment>
        </ReactCardFlip>
    )
}

export default FlipCard