import React from 'react'
import './ReactModal.css'

const ReactModal = ({ id, body }) => {
    return (
        <div className="modal fade custom-modal" id={id} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content custom-modal-content">
                    <div className="modal-body">
                        {body}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReactModal