import React, { useState, Fragment } from 'react'
import './Home.css'
import { constants } from '../../core/constants/constants'
import images from '../../core/constants/images'
import svgs from '../../core/constants/svgs'
import I18nManager from '../../core/I18nManager/I18nManager'
import ViewContainer from '../../core/routes/view-container/ViewContainer'
import ReadMoreText from '../_common/read-more-text/ReadMoreText'
import FlipCard from '../_common/flip-card/FlipCard'
import FeatureBackView from './feature-back-view/FeatureBackView'

const Home = ({ history }) => {

    const [features, setFeatures] = useState([
        {
            titleEn: `Travelling? We got you covered!`, titleAr: `ما تشيل هم السفر – فحص بي سي ار`, icon: `flaticon-charity`,
            descriptionEn: `Get your PCR test for the best prices in Riyadh (More than 38% discount)`, descriptionAr: `احصل الان على عرض فحص PCR باقل الأسعار في الرياض بخصم اكثر من 38%`,
            buttons: [{ titleEn: `All promotions`, titleAr: `جميع العروض`, action: '/promotions-bundles' }, { titleEn: `Book now`, titleAr: `احجز الحين` }],
            typeEn: `Offers`, typeAr: `العروض`,
            isFlipped: false
        },
        {
            titleEn: `Child care bundle`, titleAr: `باقة اطمئن على صحة طفلك الفضية`, icon: `flaticon-heartbeat`,
            descriptionEn: `This package includes: Complete blood count, Vitamin test, Iron analysis, Calcium analysis.`, descriptionAr: `هذه الباقة تشمل: صورة دم كاملة، تحليل فيتامين، د.تحليل مخزون الحديد، تحليل الكالسيوم`,
            buttons: [{ titleEn: `All promotions`, titleAr: `جميع الباقات`, action: '/promotions-bundles' }, { titleEn: `Book now`, titleAr: `احجز الحين` }],
            typeEn: `Bundles`, typeAr: `الباقات`,
            isFlipped: false
        },
        {
            titleEn: `Home services`, titleAr: `قسم الخدمات الصحية المنزلية`, imgIcon: svgs.homeIcon,
            descriptionEn: `Our personnel are qualified and prepared to deliver the highest level of care and attention to you or your loved ones from the comfort of your own home.`, descriptionAr: `هذه الباقة تشمل: صورة دم كاملة، تحليل فيتامين، د.تحليل مخزون الحديد، تحليل الكالسيوم`,
            buttons: [{ titleEn: `All Departments`, titleAr: `جميع الأقسام`, action: '/departments' }, { titleEn: `Book now`, titleAr: `احجز الحين` }],
            typeEn: `Sections`, typeAr: `الأقسام`,
            isFlipped: false
        },
    ])

    const [promotions, setPromotions] = useState(constants.promotions)

    const [departments, setDepartments] = useState(constants.departments)

    const [bundles, setBundles] = useState(constants.bundles)

    const doctors = [
        { titleEn: `Dr. Jamal Karim`, titleAr: `د. جمال كريم`, specialityEn: `Internal Diseases Specialist`, specialityAr: `اخصائي أمراض باطنية`, img: images.jamalKarim, },
        { titleEn: `Dr. Jihan Baltaji`, titleAr: `د. جيهان محمد بلتاجي`, specialityEn: `Pediatrician and newborn specialist`, specialityAr: `اخصائية أطفال`, img: images.jihanBaltaji, },
        { titleEn: `Dr. Lina Samour`, titleAr: `د. لينا سمور`, specialityEn: `General medicine and surgery`, specialityAr: `طب عام وجراحه عامه`, img: images.linaSamour, },
        { titleEn: `Dr. Mohamed Fathy`, titleAr: `د. محمد فتحي`, specialityEn: `Orthopedics Specialist`, specialityAr: `أخصائي جراحة العظام والكسور`, img: images.mohamedFathy, },
        { titleEn: `Dr. Hesa Al Saloli`, titleAr: `د. حصه السلولي`, specialityEn: `Obstetrician and gynecologist`, specialityAr: `أخصائية نسائية وتوليد`, img: images.hesaAlSaloli, },
        { titleEn: `Dr. Khalid Hussein`, titleAr: `د. خالد صلاح`, specialityEn: `General medicine and surgery`, specialityAr: `طب عام وجراحه عامه`, img: images.khalidHussein, },
        { titleEn: `Ahmad Abdulathim`, titleAr: `أحمد عبد العظيم`, specialityEn: `Medical Laboratory Scientist`, specialityAr: `أخصائي مختبر`, img: images.ahmadAbdulathim, },
        { titleEn: `Areej Abu Safiyeh`, titleAr: `أريج حاتم ابو صفيه`, specialityEn: `Medical Laboratory Scientist`, specialityAr: `أخصائيه مختبر`, img: images.areejAbuSafiyeh, },
        { titleEn: `Alaa Rawli`, titleAr: `الاء الرويلي`, specialityEn: `Radiology`, specialityAr: `اخصائيه اشعه`, img: images.alaaRawli, },
    ]

    const onFlipFeatureCard = (index) => (e) => {
        e.preventDefault()
        let tmpData = [...features]
        tmpData[index].isFlipped = !tmpData[index].isFlipped
        setFeatures(tmpData)
    }

    const onFlipPromotionCard = (index) => (e) => {
        e.preventDefault()
        let tmpData = [...promotions]
        tmpData[index].isFlipped = !tmpData[index].isFlipped
        setPromotions(tmpData)
    }

    const onFlipDepartmentCard = (index) => (e) => {
        e.preventDefault()
        let tmpData = [...departments]
        tmpData[index].isFlipped = !tmpData[index].isFlipped
        setDepartments(tmpData)
    }

    const onFlipBundleCard = (index) => (e) => {
        e.preventDefault()
        let tmpData = [...bundles]
        tmpData[index].isFlipped = !tmpData[index].isFlipped
        setBundles(tmpData)
    }

    const goToPage = (path) => (e) => {
        e.preventDefault()
        history.push(path)
    }

    return (
        <ViewContainer>

            <section className="main-slider">
                <div className="rev_slider_wrapper fullwidthbanner-container" id="rev_slider_one_wrapper" data-source="gallery">
                    <div className="rev_slider fullwidthabanner" id="rev_slider_one" data-version="5.4.1">
                        <ul>
                            <li data-index="rs-1" data-transition="fade" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="300" data-thumb="" data-rotate="0" data-saveperformance="off" data-title="Slide" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
                                <img alt="" className="rev-slidebg" data-bgfit="cover" data-bgparallax="20" data-bgposition="center center" data-bgrepeat="no-repeat" data-no-retina="" src={images[I18nManager.isRTL() ? "topSliderDoctorAr" : "topSliderDoctorEn"]} />
                                <div className="tp-caption"
                                    data-paddingbottom="[0,0,0,0]"
                                    data-paddingleft="[15,15,15,15]"
                                    data-paddingright="[0,0,0,0]"
                                    data-paddingtop="[0,0,0,0]"
                                    data-responsive_offset="on"
                                    data-type="text"
                                    data-height="none"
                                    data-width="['750','750','750','650']"
                                    data-whitespace="normal"
                                    data-hoffset="['0','0','0','0']"
                                    data-voffset="['-180','-170','-180','-180']"
                                    data-x="['left','left','left','left']"
                                    data-y="['middle','middle','middle','middle']"
                                    data-textalign="['top','top','top','top']"
                                    data-frames='[{"delay":1000,"speed":1500,"frame":"0","from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'>
                                    <span className="title">{I18nManager.isRTL() ? "أهلا بك في مجمع الدكتور فهد بافقيه الطبي" : "Welcome to Fahed Bafaqih Medical Complex"}</span>
                                </div>

                                <div className="tp-caption"
                                    data-paddingbottom="[0,0,0,0]"
                                    data-paddingleft="[15,15,15,15]"
                                    data-paddingright="[15,15,15,15]"
                                    data-paddingtop="[0,0,0,0]"
                                    data-responsive_offset="on"
                                    data-type="text"
                                    data-height="none"
                                    data-width="['750','750','750','650']"
                                    data-whitespace="normal"
                                    data-hoffset="['0','0','0','0']"
                                    data-voffset="['-100','-95','-100','-115']"
                                    data-x="['left','left','left','left']"
                                    data-y="['middle','middle','middle','middle']"
                                    data-textalign="['top','top','top','top']"
                                    data-frames='[{"delay":1000,"speed":1500,"frame":"0","from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'>
                                    <h2>{I18nManager.isRTL() ? "صحة أفضل" : "Better health"}</h2>
                                    <h2>{I18nManager.isRTL() ? "لحياة أفضل" : "For a better lifestyle"}</h2>
                                </div>

                                <div className="tp-caption"
                                    data-paddingbottom="[0,0,0,0]"
                                    data-paddingleft="[15,15,15,15]"
                                    data-paddingright="[15,15,15,15]"
                                    data-paddingtop="[0,0,0,0]"
                                    data-responsive_offset="on"
                                    data-type="text"
                                    data-height="none"
                                    data-width="['700','750','700','450']"
                                    data-whitespace="normal"
                                    data-hoffset="['0','0','0','0']"
                                    data-voffset="['0','0','0','0']"
                                    data-x="['left','left','left','left']"
                                    data-y="['middle','middle','middle','middle']"
                                    data-textalign="['top','top','top','top']"
                                    data-frames='[{"delay":1000,"speed":1500,"frame":"0","from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'>
                                    <div className="text">{I18nManager.isRTL() ? "مجمع الدكتور فهد بافقيه الطبي المتكامل بالرياض يوفر لكم مرافق علاج " : "Fahad Bafaqih Medical Complex in Riyadh offers world-class treatment facilities and medical clinics, with over 15,000 patients serviced to date."}<br />{I18nManager.isRTL() ? "وعيادات طبية ذات مستوى عالمي حيث تم خدمة اكثر من 15000 مريض حتى اليوم." : ""}</div>
                                </div>

                                <div className="tp-caption tp-resizeme"
                                    data-paddingbottom="[0,0,0,0]"
                                    data-paddingleft="[15,15,15,15]"
                                    data-paddingright="[15,15,15,15]"
                                    data-paddingtop="[0,0,0,0]"
                                    data-responsive_offset="on"
                                    data-type="text"
                                    data-height="none"
                                    data-whitespace="normal"
                                    data-width="['650','650','700','300']"
                                    data-hoffset="['0','0','0','0']"
                                    data-voffset="['80','90','90','140']"
                                    data-x="['left','left','left','left']"
                                    data-y="['middle','middle','middle','middle']"
                                    data-textalign="['top','top','top','top']"
                                    data-frames='[{"delay":1000,"speed":2000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'>
                                    <div className="btn-box">
                                        <a href="/about-us" onClick={goToPage('/about-us')} className="theme-btn btn-style-one"><span className="btn-title">{I18nManager.isRTL() ? "مزيد من المعلومات" : "More information"}</span></a>
                                        <a href="tel:966556189216" className="theme-btn btn-style-two"><span className="btn-title">   <i className={"flaticon-phone-1 " + (I18nManager.isRTL() ? "ml-1" : "mr-1")}></i>{I18nManager.isRTL() ? "اتصل الحين" : "Call now"}</span></a>
                                    </div>
                                </div>
                            </li>

                            <li data-index="rs-2" data-transition="fade" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="300" data-thumb="" data-rotate="0" data-saveperformance="off" data-title="Slide" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
                                <img alt="" className="rev-slidebg" data-bgfit="cover" data-bgparallax="20" data-bgposition="center center" data-bgrepeat="no-repeat" data-no-retina="" src={images[I18nManager.isRTL() ? "topSliderCoronaAr" : "topSliderCoronaEn"]} />
                                <div className="tp-caption"
                                    data-paddingbottom="[0,0,0,0]"
                                    data-paddingleft="[15,15,15,15]"
                                    data-paddingright="[0,0,0,0]"
                                    data-paddingtop="[0,0,0,0]"
                                    data-responsive_offset="on"
                                    data-type="text"
                                    data-height="none"
                                    data-width="['750','750','750','650']"
                                    data-whitespace="normal"
                                    data-hoffset="['0','0','0','0']"
                                    data-voffset="['-180','-170','-180','-180']"
                                    data-x="['left','left','left','left']"
                                    data-y="['middle','middle','middle','middle']"
                                    data-textalign="['top','top','top','top']"
                                    data-frames='[{"delay":1000,"speed":1500,"frame":"0","from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'>
                                    <span className="title">{I18nManager.isRTL() ? "أهلا بك في مجمع الدكتور فهد بافقيه الطبي" : "Welcome to Fahed Bafaqih Medical Complex"}</span>
                                </div>

                                <div className="tp-caption"
                                    data-paddingbottom="[0,0,0,0]"
                                    data-paddingleft="[15,15,15,15]"
                                    data-paddingright="[15,15,15,15]"
                                    data-paddingtop="[0,0,0,0]"
                                    data-responsive_offset="on"
                                    data-type="text"
                                    data-height="none"
                                    data-width="['750','750','750','650']"
                                    data-whitespace="normal"
                                    data-hoffset="['0','0','0','0']"
                                    data-voffset="['-100','-95','-100','-115']"
                                    data-x="['left','left','left','left']"
                                    data-y="['middle','middle','middle','middle']"
                                    data-textalign="['top','top','top','top']"
                                    data-frames='[{"delay":1000,"speed":1500,"frame":"0","from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'>
                                    <h2>{I18nManager.isRTL() ? "عرض فحص كورونا معتمد" : "Get your PCR test"}</h2>
                                    <h2>{I18nManager.isRTL() ? "للسفر بـ185 ريال فقط" : "Only for 185 SAR"}</h2>
                                </div>

                                <div className="tp-caption"
                                    data-paddingbottom="[0,0,0,0]"
                                    data-paddingleft="[15,15,15,15]"
                                    data-paddingright="[15,15,15,15]"
                                    data-paddingtop="[0,0,0,0]"
                                    data-responsive_offset="on"
                                    data-type="text"
                                    data-height="none"
                                    data-width="['700','750','700','450']"
                                    data-whitespace="normal"
                                    data-hoffset="['0','0','0','0']"
                                    data-voffset="['0','0','0','0']"
                                    data-x="['left','left','left','left']"
                                    data-y="['middle','middle','middle','middle']"
                                    data-textalign="['top','top','top','top']"
                                    data-frames='[{"delay":1000,"speed":1500,"frame":"0","from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'>
                                    <div className="text">{I18nManager.isRTL() ? "أحصل الآن على فحص بي سي ار معتمد للسفر بأفضل الأسعار في الرياض" : "Get your PCR test for the best prices in Riyadh"}</div>
                                </div>

                                <div className="tp-caption tp-resizeme"
                                    data-paddingbottom="[0,0,0,0]"
                                    data-paddingleft="[15,15,15,15]"
                                    data-paddingright="[15,15,15,15]"
                                    data-paddingtop="[0,0,0,0]"
                                    data-responsive_offset="on"
                                    data-type="text"
                                    data-height="none"
                                    data-whitespace="normal"
                                    data-width="['650','650','700','300']"
                                    data-hoffset="['0','0','0','0']"
                                    data-voffset="['80','90','90','140']"
                                    data-x="['left','left','left','left']"
                                    data-y="['middle','middle','middle','middle']"
                                    data-textalign="['top','top','top','top']"
                                    data-frames='[{"delay":1000,"speed":2000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'>
                                    <div className="btn-box">
                                        <a href="/about-us" onClick={goToPage('/about-us')} className="theme-btn btn-style-one"><span className="btn-title">{I18nManager.isRTL() ? "مزيد من المعلومات" : "More information"}</span></a>
                                        <a href="tel:966556189216" className="theme-btn btn-style-two"><span className="btn-title"><i className={"flaticon-phone-1 " + (I18nManager.isRTL() ? "ml-1" : "mr-1")}></i>{I18nManager.isRTL() ? "اتصل الحين" : "Call now"}</span></a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="top-features">
                <div className="auto-container">
                    <div className="row">
                        {features.map((item, index) => (
                            <FlipCard key={index} isFlipped={item?.isFlipped}
                                containerClassName="feature-block col-lg-4 col-md-6 col-sm-12"
                                frontChildren={
                                    <div className="inner-box d-flex flex-column justify-content-between" onClick={onFlipFeatureCard(index)}>
                                        <div className="banner">
                                            <span>{item?.[I18nManager.isRTL() ? "typeAr" : "typeEn"]}</span>
                                        </div>
                                        <div>
                                            {item?.icon && <span className={"icon " + item?.icon}></span>}
                                            {item?.imgIcon && <span className="icon">
                                                <img width="65" src={item?.imgIcon} alt="home" title="home" />
                                            </span>}
                                            <h4><a href="/#">{item?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]}</a></h4>
                                            <p>{item?.[I18nManager.isRTL() ? "descriptionAr" : "descriptionEn"]}</p>
                                        </div>
                                        <div className="btn-box d-flex align-items-center justify-content-center" style={{ marginTop: 10 }}>
                                            {item.buttons[0] && <a href={item.buttons[0].action} onClick={goToPage(item.buttons[0].action)} className="theme-btn btn-style-one" style={{ padding: 5, marginRight: (!I18nManager.isRTL() && item.buttons[1]) ? 10 : 0, marginLeft: (I18nManager.isRTL() && item.buttons[1]) ? 10 : 0 }}><span className="btn-title">{item.buttons[0][I18nManager.isRTL() ? "titleAr" : "titleEn"]}</span></a>}
                                            {item.buttons[1] && <>&nbsp;<a href="tel:966556189216" className="theme-btn btn-style-two" style={{ padding: 5 }}><span className="btn-title">{item.buttons[1][I18nManager.isRTL() ? "titleAr" : "titleEn"]}</span></a></>}
                                        </div>
                                    </div>
                                }
                                backChildrem={
                                    <div className="inner-box d-flex flex-column justify-content-between">
                                        <div onClick={onFlipFeatureCard(index)} style={{ position: 'absolute', cursor: 'pointer', zIndex: 10, top: 10, right: 15 }}>
                                            <i className="fas fa-times"></i>
                                        </div>
                                        <FeatureBackView index={index} item={item} />
                                    </div>
                                }
                            />
                        ))}
                    </div>
                </div>
            </section>

            <section className="services-section-five">
                <div className="auto-container">
                    <div className="sec-title text-center">
                        <span className="sub-title">{I18nManager.isRTL() ? "عروضنا" : "Our Offers"}</span>
                        <h2>{I18nManager.isRTL() ? "أفضل العروض" : "What We Offer"}</h2>
                        <span className="divider"></span>
                    </div>
                    <div className="carousel-outer">
                        <div className="services-carousel owl-carousel owl-theme default-dots">
                            {promotions.map((item, index) => (
                                <FlipCard key={index} isFlipped={item?.isFlipped}
                                    containerClassName="service-block-five"
                                    frontChildren={
                                        <div className="shop-item">
                                            <div className="inner-box">
                                                <div className="image-box" onClick={onFlipPromotionCard(index)}>
                                                    <figure className="image"><a href="department-detail.html"><img src={item?.img} alt="fbmc offer pcr" title="fbmc offer pcr" /></a></figure>
                                                    <span className="onsale">{I18nManager.isRTL() ? "عرض" : "Sale"}</span>
                                                </div>
                                                <div className="lower-content">
                                                    <div onClick={onFlipPromotionCard(index)}>
                                                        <h4 className="name"><a href="shop-single.html">{item[I18nManager.isRTL() ? "titleAr" : "titleEn"]}</a></h4>
                                                        {item?.price && <div className="price">{item.price} {I18nManager.isRTL() ? "ريال" : "SAR"}</div>}
                                                        {item?.discount && <div className="price">{item.discount} %</div>}
                                                        {item[I18nManager.isRTL() ? "descriptionAr" : "descriptionEn"] &&
                                                            item[I18nManager.isRTL() ? "descriptionAr" : "descriptionEn"].map((el, i) => (
                                                                <p key={i}>{el}</p>
                                                            ))}
                                                    </div>
                                                    <div className="d-flex" style={{ marginTop: 10, }}>
                                                        <a style={{ color: '#66C18F', backgroundColor: '#ffffff', boxShadow: `0 0 10px rgb(0 0 0 / 10%)` }} href="/#"
                                                            onClick={onFlipPromotionCard(index)}
                                                            className="theme-btn add-to-cart">{I18nManager.isRTL() ? "احجز الحين" : "Book now"}</a>&nbsp;&nbsp;&nbsp;
                                                        <a style={{ whiteSpace: 'nowrap', color: '#ffffff', backgroundColor: '#66C18F', boxShadow: `0 0 10px rgb(0 0 0 / 10%)` }} href="tel:966556189216"
                                                            className="theme-btn add-to-cart">{I18nManager.isRTL() ? "اتصل الحين" : "Call now"}<i className={"flaticon-phone-1 " + (I18nManager.isRTL() ? "ml-1" : "mr-1")}></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    backChildrem={
                                        <div className="inner-box d-flex flex-column justify-content-between p-4">
                                            <div onClick={onFlipPromotionCard(index)} style={{ position: 'absolute', cursor: 'pointer', zIndex: 10, top: 10, right: 15 }}>
                                                <i className="fas fa-times"></i>
                                            </div>
                                            <FeatureBackView index={index} item={item} />
                                        </div>
                                    }
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            <section className="services-section">
                <div className="auto-container">
                    <div className="sec-title text-center">
                        <span className="sub-title">{I18nManager.isRTL() ? "خدماتنا وأقسامنا" : "OUR SERVICES"}</span>
                        <h2>{I18nManager.isRTL() ? "خدمات طبية متكاملة في مكان واحد لجميع التخصصات" : "We Care Our Patients."}</h2>
                        <span className="divider"></span>
                    </div>
                    <div className="row">
                        {departments.map((item, index) => (
                            <Fragment key={index}>
                                {item[I18nManager.isRTL() ? "titleAr" : "titleEn"] &&
                                    <div className="col-lg-4 col-md-6 col-12" key={index}>
                                        <FlipCard isFlipped={item?.isFlipped}
                                            containerClassName="service-block"
                                            frontChildren={
                                                <div className="inner-box">
                                                    {item?.icon && <span className={"icon " + item?.icon}></span>}
                                                    {item?.imgIcon && <span className="icon">
                                                        <img width="50" height="50" src={item?.imgIcon} alt="home" title="home" />
                                                    </span>}
                                                    <h5 onClick={onFlipDepartmentCard(index)}><a href="tel:966920025909">{item?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]}</a></h5>
                                                    {item[I18nManager.isRTL() ? "descriptionAr" : "descriptionEn"] &&
                                                        item[I18nManager.isRTL() ? "descriptionAr" : "descriptionEn"].map((el, i) => (
                                                            <ReadMoreText key={i} className="text" text={el} onClick={onFlipDepartmentCard(index)} />
                                                        ))
                                                    }
                                                    <div className="btn-box d-flex align-items-center justify-content-center" style={{ marginTop: 10 }}>
                                                        <a href="/#" className="theme-btn btn-style-one" onClick={onFlipDepartmentCard(index)} style={{ padding: 5 }}><span className="btn-title">{I18nManager.isRTL() ? "احجز الحين" : "Book now"}</span></a>
                                                        &nbsp;<a href={'/department/' + index} onClick={goToPage('/department/' + index)} className="theme-btn btn-style-two" style={{ padding: 5 }}><span className="btn-title">{I18nManager.isRTL() ? "مزيد" : "More"}</span></a>
                                                    </div>
                                                </div>
                                            }
                                            backChildrem={
                                                <div className="inner-box d-flex flex-column justify-content-between p-5" style={{ backgroundColor: '#ffffff' }}>
                                                    <div onClick={onFlipDepartmentCard(index)} style={{ position: 'absolute', cursor: 'pointer', zIndex: 10, top: 10, right: 15 }}>
                                                        <i className="fas fa-times"></i>
                                                    </div>
                                                    <FeatureBackView index={index} item={item} />
                                                </div>
                                            }
                                        />
                                    </div>
                                }
                            </Fragment>
                        ))}
                    </div>
                    <div className="d-flex justify-content-center">
                        <a href="tel:966556189216" onClick={goToPage('/departments')} id="appointment-btn" className="theme-btn btn-style-one"><span className="btn-title">{I18nManager.isRTL() ? "جميع الأقسام" : "All Departments"}</span></a>
                    </div>
                </div>
            </section>

            <section className="services-section-five">
                <div className="auto-container">
                    <div className="sec-title text-center">
                        <span className="sub-title">{I18nManager.isRTL() ? "خبرائنا" : "Our Doctors"}</span>
                        <h2>{I18nManager.isRTL() ? "فريقنا الطبي" : "Our Dedicated Doctors Team"}</h2>
                        <span className="divider"></span>
                    </div>
                    <div className="carousel-outer">
                        <div className="doctors-carousel owl-carousel owl-theme default-dots">
                            {doctors.map((item, index) => (
                                <div key={index} className="service-block-five">
                                    <div className="team-block wow fadeInUp">
                                        <div className="inner-box">
                                            <figure className="image"><img src={item.img} alt={item?.titleEn} title={item?.titleEn} /></figure>
                                            <ul className="social-links">
                                                <li><a href="https://www.facebook.com/Fbmcsa/" target="_blank" rel="noreferrer"><span className="fab fa-facebook-f"></span></a></li>
                                                <li><a href="https://twitter.com/FbmcSa" target="_blank" rel="noreferrer"><span className="fab fa-twitter"></span></a></li>
                                                <li><a href="https://www.instagram.com/Fbmc.Sa/" target="_blank" rel="noreferrer"><span className="fab fa-instagram"></span></a></li>
                                                <li><a href="https://www.youtube.com/channel/UCpyFdu5FBnAGcTSu6qPpFoQ" target="_blank" rel="noreferrer"><span className="fab fa-youtube"></span></a></li>
                                            </ul>
                                            <div className="info-box">
                                                <h4 className="name"><a href="doctor-detail.html">{item?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]}</a></h4>
                                                <span className="designation">{item?.[I18nManager.isRTL() ? "specialityAr" : "specialityEn"]}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            <section className="fun-fact-section-two">
                <div className="auto-container">
                    <div className="row">
                        <div className="counter-column col-lg-3 col-md-6 col-sm-12 wow fadeInUp">
                            <div className="count-box">
                                <div className="icon-box"><span className="icon flaticon-user-experience"></span></div>
                                <h4 className="counter-title">{I18nManager.isRTL() ? "سنوات الخبرة" : "Years of Experience"}</h4>
                                <div className="d-flex align-items-center justify-content-center">
                                    <span className="count-text" data-speed="3000" data-stop="40">0</span><span style={{ fontWeight: 700, fontSize: 36 }}>+</span>
                                </div>
                            </div>
                        </div>

                        <div className="counter-column col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="400ms">
                            <div className="count-box">
                                <div className="icon-box"><span className="icon flaticon-hospital"></span></div>
                                <h4 className="counter-title">{I18nManager.isRTL() ? "الأقسام المتخصصة" : "Departments"}</h4>
                                <div className="d-flex align-items-center justify-content-center">
                                    <span className="count-text" data-speed="3000" data-stop="9">0</span><span style={{ fontWeight: 700, fontSize: 36 }}>+</span>
                                </div>
                            </div>
                        </div>

                        <div className="counter-column col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="800ms">
                            <div className="count-box">
                                <div className="icon-box"><span className="icon flaticon-team"></span></div>
                                <h4 className="counter-title">{I18nManager.isRTL() ? "الأطباء والمختصين" : "Workforce"}</h4>
                                <div className="d-flex align-items-center justify-content-center">
                                    <span className="count-text" data-speed="3000" data-stop="15">0</span><span style={{ fontWeight: 700, fontSize: 36 }}>+</span>
                                </div>
                            </div>
                        </div>

                        <div className="counter-column col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="1200ms">
                            <div className="count-box">
                                <div className="icon-box"><span className="icon flaticon-add-friend"></span></div>
                                <h4 className="counter-title">{I18nManager.isRTL() ? "مرضى ومراجعين تم خدمتهم" : "Happy Patients served"}</h4>
                                <div className="d-flex align-items-center justify-content-center">
                                    <span className="count-text" data-speed="3000" data-stop="10000">0</span><span style={{ fontWeight: 700, fontSize: 36 }}>+</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="testimonial-section">
                <div className="auto-container">
                    <div className="sec-title text-center">
                        <span className="title">{I18nManager.isRTL() ? "تقييمات المرضى" : "HAPPY Patients"}</span>
                        <h2>{I18nManager.isRTL() ? "ماذا يقول عملاؤنا" : "What Says Our Patients"}</h2>
                        <span className="divider"></span>
                    </div>

                    <div className="testimonial-outer">
                        <div className="client-testimonial-carousel owl-carousel owl-theme">
                            {constants.testimonals.map((item, index) => (
                                <div className="testimonial-block" key={index}>
                                    <div className="inner-box">
                                        <div className="text">{item[I18nManager.isRTL() ? "descriptionAr" : "descriptionEn"]}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="client-thumb-outer">
                            <div className="client-thumbs-carousel owl-carousel owl-theme">
                                {constants.testimonals.map((item, index) => (
                                    <div className="thumb-item" key={index}>
                                        <figure className="thumb-box"><img src={svgs.googleLogo} alt="" /></figure>
                                        <div className="author-info">
                                            <span className="icon fa fa-quote-left"></span>
                                            <div className="author-name">{item[I18nManager.isRTL() ? "titleAr" : "titleEn"]}</div>
                                            {/* <div className="designation">Restaurant Owner</div> */}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pricing-section">
                <div className="auto-container">
                    <div className="sec-title text-center">
                        <span className="sub-title">{I18nManager.isRTL() ? "الباقات" : "Bundles"}</span>
                        <h2>{I18nManager.isRTL() ? "تفاصيل الباقات" : "Bundles Details"}</h2>
                        <span className="divider"></span>
                    </div>

                    <div className="outer-box">
                        <div className="row">
                            {bundles.map((item, index) => (
                                <div className="pricing-block col-lg-3 col-md-6 col-sm-12" key={index}>
                                    <FlipCard isFlipped={item?.isFlipped}
                                        containerClassName=""
                                        frontChildren={
                                            <div className="inner-box h-100" key={index} onClick={onFlipBundleCard(index)}>
                                                <div>
                                                    <div className="price-box">
                                                        <h4 className="price">{item?.price} {I18nManager.isRTL() ? "ريال" : "SAR"}</h4>
                                                        {/* <div className="validaty">Per Month</div> */}
                                                    </div>
                                                    <h3 className="title">{item?.[I18nManager.isRTL() ? "bundleNameAr" : "bundleNameEn"]}</h3>
                                                    <ul className="features">
                                                        {item?.[I18nManager.isRTL() ? "bundleDetailsAr" : "bundleDetailsEn"]?.map((el, index) => (
                                                            <li key={index}>{el}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <div className="btn-box">
                                                    <a href="/#" className="theme-btn">{I18nManager.isRTL() ? "احجز الحين" : "Get the bundle"}</a>
                                                </div>
                                            </div>
                                        }
                                        backChildrem={
                                            <div className="inner-box d-flex flex-column justify-content-between p-5">
                                                <div onClick={onFlipBundleCard(index)} style={{ position: 'absolute', cursor: 'pointer', zIndex: 10, top: 10, right: 15 }}>
                                                    <i className="fas fa-times"></i>
                                                </div>
                                                <FeatureBackView index={index} item={item} />
                                            </div>
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            <section className="clients-section">
                <div className="auto-container">
                    <div className="sec-title text-center">
                        <span className="sub-title">{I18nManager.isRTL() ? "الشركات" : "Companies"}</span>
                        <h2>{I18nManager.isRTL() ? "شركات التأمين المعتمدة" : "Approved Insurance Companies"}</h2>
                        <span className="divider"></span>
                    </div>
                    <div className="sponsors-outer">
                        <ul className="clients-carousel owl-carousel owl-theme">
                            <li className="slide-item"> <a href="/#"><img style={{ width: 100, height: 100 }} src={images.taameenAksa} alt="taameen aksa" title="taameen aksa" /></a> </li>
                            <li className="slide-item"> <a href="/#"><img style={{ width: 100, height: 100 }} src={images.taameenEtihadKhalij} alt="taameen etihad khalij" title="taameen etihad khalij" /></a> </li>
                            <li className="slide-item"> <a href="/#"><img style={{ width: 100, height: 100 }} src={images.taameenMalaz} alt="taameen malaz" title="taameen malaz" /></a> </li>
                        </ul>
                    </div>
                </div>
            </section>

        </ViewContainer>
    )
}

export default Home
