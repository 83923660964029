const images = {
    docnowLogo: require('../../assets/images/docnow-logo.png').default,

    topSliderClinicAr: require('../../assets/images/top-slider-clinic-ar.jpg').default,
    topSliderClinicEn: require('../../assets/images/top-slider-clinic-en.jpg').default,
    topSliderCoronaAr: require('../../assets/images/top-slider-corona-ar.jpg').default,
    topSliderCoronaEn: require('../../assets/images/top-slider-corona-en.jpg').default,
    topSliderDoctorAr: require('../../assets/images/top-slider-doctor-ar.jpeg').default,
    topSliderDoctorEn: require('../../assets/images/top-slider-doctor-en.jpeg').default,

    offerPcr: require('../../assets/images/offer-pcr.jpg').default,
    offerDoctor: require('../../assets/images/offer-doctor.jpg').default,
    offerPregnant: require('../../assets/images/offer-pregnant.jpg').default,
    offerChild: require('../../assets/images/offer-child.jpg').default,
    taameenAksa: require('../../assets/images/taameen-aksa.jpg').default,
    taameenEtihadKhalij: require('../../assets/images/taameen-etihad-khalij.jpg').default,
    taameenMalaz: require('../../assets/images/taameen-malaz.jpg').default,
    fbmcFooterBg: require('../../assets/images/fbmc-footer-bg.jpg').default,
    aboutUs1: require('../../assets/images/about-us-1.png').default,
    aboutUs2: require('../../assets/images/about-us-2.png').default,
    aboutUs3: require('../../assets/images/about-us-3.png').default,
    signatureFahad: require('../../assets/images/signature-fahad.png').default,
    emergencyDepartment: require('../../assets/images/emergency-department.jpg').default,
    homeServicesDepartment: require('../../assets/images/home-services-department.jpg').default,
    internalMedicineDepartment: require('../../assets/images/internal-medicine-department.jpg').default,
    laboratoryDepartment: require('../../assets/images/laboratory-department.jpg').default,
    obstetricsAndGynecologyDepartment: require('../../assets/images/obstetrics-and-gynecology-department.jpg').default,
    orthopedicClinicDepartment: require('../../assets/images/orthopedic-clinic-department.jpg').default,
    pediatricsDepartment: require('../../assets/images/pediatrics-department.jpg').default,
    radiolagyDepartment: require('../../assets/images/radiolagy-department.jpg').default,
    surgeryClinicDepartment: require('../../assets/images/surgery-clinic-department.jpg').default,

    jamalKarim: require('../../assets/images/jamal-karim.jpg').default,
    ahmadAbdulathim: require('../../assets/images/ahmad-abdulathim.jpg').default,
    khalidHussein: require('../../assets/images/khalid-hussein.jpg').default,
    mohamedFathy: require('../../assets/images/mohamed-fathy.jpg').default,
    areejAbuSafiyeh: require('../../assets/images/areej-abu-safiyeh.jpg').default,
    jihanBaltaji: require('../../assets/images/jihan-baltaji.jpg').default,
    hesaAlSaloli: require('../../assets/images/hesa-al-saloli.jpg').default,
    linaSamour: require('../../assets/images/lina-samour.jpg').default,
    alaaRawli: require('../../assets/images/alaa-rawli.jpg').default,

    headerContactUsAr: require('../../assets/images/header-contact-us-ar.jpg').default,
    headerContactUsEn: require('../../assets/images/header-contact-us-en.jpg').default,
    headerDepartmentAr: require('../../assets/images/header-department-ar.jpg').default,
    headerDepartmentEn: require('../../assets/images/header-department-en.jpg').default,
    headerJobAr: require('../../assets/images/header-job-ar.jpg').default,
    headerJobEn: require('../../assets/images/header-job-en.jpg').default,
    headerOfferAr: require('../../assets/images/header-offer-ar.jpg').default,
    headerOfferEn: require('../../assets/images/header-offer-en.jpg').default,
    headerAboutUsAr: require('../../assets/images/header-about-us-ar.jpg').default,

    initialModalPopup: require('../../assets/images/initial-modal-popup.jpg').default,
}

export default images