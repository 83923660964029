import React, { useEffect } from 'react'
import svgs from '../../../constants/svgs'
import I18nManager from '../../../I18nManager/I18nManager'
import { useHistory } from 'react-router-dom'
import ViewDisplay from '../../../../components/_common/view-display/ViewDisplay'

const MainNavBar = () => {
    let history = useHistory()

    useEffect(() => {
        try {
            setTimeout(() => {
                if (document.getElementsByClassName("mm-navbar__title")) {
                    const mobileNavBarTitle = document.getElementsByClassName("mm-navbar__title");
                    if (mobileNavBarTitle[0].getElementsByTagName('span')) {
                        mobileNavBarTitle[0].getElementsByTagName('span')[0].innerText = (I18nManager.isRTL() ? "القائمة" : "Menu")
                    }
                }
            }, 2000);
        } catch (error) {

        }
    }, [])

    const data = [
        { titleEn: `Home`, titleAr: `الصفحة الرئيسية`, path: `/home` },
        { titleEn: `About`, titleAr: `معلوماتنا`, path: `/about-us` },
        { titleEn: `Departments`, titleAr: `أقسامنا`, path: `/departments` },
        { titleEn: `Promotions and bundles`, titleAr: `العروض والباقات`, path: `/promotions-bundles` },
        { titleEn: `Contact Us`, titleAr: `تواصل معنا`, path: `/contact-us` },
        { titleEn: `Careers`, titleAr: `وظائف`, path: `/careers` },
    ]

    const onChangeLanguage = (e) => {
        e.preventDefault()
        I18nManager.setCookieDirValue(!I18nManager.isRTL())
    }

    const goToPage = (path) => (e) => {
        e.preventDefault()
        history.push(path)
    }

    return (
        <header className="main-header header-style-one">
            <div className="header-top">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="top-left">
                            <ul className="contact-list clearfix">
                                {I18nManager.isRTL() && <li><img src={svgs.exchangeIcon} width="20" height="20" alt="change lang" title="change lang" /><a href="/#" onClick={onChangeLanguage} style={{ fontWeight: 'bold', color: '#777777', marginRight: 5 }}>English</a></li>}
                                <li><i className="flaticon-hospital-1"></i>{I18nManager.isRTL() ? "الساهرية، حى الورود، الرياض" : "As Sahriyah, Al Wurud, Riyadh"}</li>
                                <li><i className="flaticon-back-in-time"></i>{I18nManager.isRTL() ? "السبت - الخميس 08:30 ص 12:00 ص" : "Sat - Thu 08:30 AM 12:00 AM"}</li>
                                <li><i className="flaticon-phone-1"></i><a href="tel:966556189216" style={{ color: '#777777' }}>0556189216</a></li>
                                <li><i className="flaticon-email"></i><a href="mailto:info@fbmc.sa" style={{ color: '#777777' }}>info@fbmc.sa</a></li>
                                {!I18nManager.isRTL() && <li><img src={svgs.exchangeIcon} width="20" height="20" alt="change lang" title="change lang" /><a href="/#" onClick={onChangeLanguage} style={{ fontWeight: 'bold', color: '#777777', marginLeft: 5 }}>عربي</a></li>}
                            </ul>
                        </div>
                        <div className="top-right">
                            <ul className="social-icon-one">
                                <li><a href="https://www.facebook.com/Fbmcsa/" target="_blank" rel="noreferrer"><span className="fab fa-facebook-f"></span></a></li>
                                <li><a href="https://twitter.com/FbmcSa" target="_blank" rel="noreferrer"><span className="fab fa-twitter"></span></a></li>
                                <li><a href="https://www.instagram.com/Fbmc.Sa/" target="_blank" rel="noreferrer"><span className="fab fa-instagram"></span></a></li>
                                <li><a href="https://www.youtube.com/channel/UCpyFdu5FBnAGcTSu6qPpFoQ" target="_blank" rel="noreferrer"><span className="fab fa-youtube"></span></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="header-lower">
                <div className="auto-container">
                    <div className="main-box">
                        <div className="logo-box">
                            <div className="logo"><a href="/home" onClick={goToPage('/home')}><img src={svgs.fbmcLogo} alt="fbmc logo" title="fbmc logo" width="194" height="45" /></a></div>
                        </div>
                        <div className="nav-outer">
                            <nav className="nav main-menu">
                                <ul className="navigation" id="navbar">
                                    {data.map((item, index) => (
                                        <li key={index}><a href={item?.path} onClick={goToPage(item.path)}>{item?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]}</a></li>
                                    ))}
                                    <ViewDisplay
                                        desktopView={<></>}
                                        mobileView={<li className="mm-listitem__text"><a href="/#" onClick={onChangeLanguage}>{I18nManager.isRTL() ? "English" : "عربي"}</a></li>}
                                    />
                                </ul>
                            </nav>
                            <div className="outer-box">
                                {/* <button className="search-btn"><span className="fa fa-search"></span></button> */}
                                <a href="tel:966556189216" id="appointment-btn" className="theme-btn btn-style-one"><span className="btn-title">{I18nManager.isRTL() ? "حجز موعد" : "Appointment"}</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sticky-header">
                <div className="auto-container">
                    <div className="main-box">
                        <div className="logo-box">
                            <div className="logo"><a href="/home" onClick={goToPage('/home')}><img src={svgs.fbmcLogo} alt="fbmc logo" title="fbmc logo" width="194" height="45" /></a></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mobile-header">
                <div className="logo"><a href="/home" onClick={goToPage('/home')}><img src={svgs.fbmcLogo} alt="fbmc logo" title="fbmc logo" width="194" height="45" /></a></div>

                <div className="nav-outer clearfix">
                    <div className="outer-box">
                        {/* <div className="search-box">
                            <button className="search-btn mobile-search-btn"><i className="flaticon-magnifying-glass"></i></button>
                        </div> */}
                        <a href="#nav-mobile" className="mobile-nav-toggler navbar-trigger"><span className="fa fa-bars"></span></a>
                    </div>
                </div>
            </div>

            <div id="nav-mobile"></div>

        </header>
    )
}

export default MainNavBar
