export const inputValidation = {
    MobileNo: {
        ar: 'قمت بإدخال غير صحيح ادخل رمز البلد ثم رقم جوالك',
        en: 'Invalid! please enter country calling code first.'
    },
    FirstName: {
        ar: 'قمت بإدخال غير صحيح لا تستخدم رموز أو أحرف من لغتين مختلفتين',
        en: 'You have entered invalid entry, don’t use symbols or letters from two different languages.'
    },
    LastName: {
        ar: 'قمت بإدخال غير صحيح لا تستخدم رموز أو أحرف من لغتين مختلفتين',
        en: 'You have entered invalid entry, don’t use symbols or letters from two different languages.'
    },
    FullName: {
        ar: 'قم بإدخال اسمين على الأقل',
        en: 'Enter at least two words'
    },
    Email: {
        ar: 'عنوان البريد الإلكتروني غير صالح',
        en: 'Invalid email address'
    },
    Subject: {
        ar: 'أدخل أربعة أحرف على الأقل',
        en: 'Enter at least four letters'
    }
}