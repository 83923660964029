import React, { Fragment } from 'react'
import images from '../../../core/constants/images'
import I18nManager from '../../../core/I18nManager/I18nManager'
import ViewContainer from '../../../core/routes/view-container/ViewContainer'
import { useParams } from 'react-router-dom'
import { constants } from '../../../core/constants/constants'
import FeatureBackView from '../../home/feature-back-view/FeatureBackView'

const DepartmentDetails = () => {
    let { id } = useParams()

    const department = constants.departments[id]

    return (
        <ViewContainer>
            <section className="page-title" style={{ backgroundImage: `url(${images[I18nManager.isRTL() ? "headerDepartmentAr" : "headerDepartmentEn"]})` }}>
                <div className="auto-container">
                    <div className="title-outer">
                        <h1>{I18nManager.isRTL() ? "أقسامنا" : "Departments"}</h1>
                        <ul className="page-breadcrumb">
                            <li><a href="index.html">{I18nManager.isRTL() ? "الصفحة الرئيسية" : "Home"}</a></li>
                            <li>{I18nManager.isRTL() ? "أقسامنا" : "Department Details"}</li>
                        </ul>
                    </div>
                </div>
            </section>
            {department?.titleAr && <div className="sidebar-page-container">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="content-side col-12 order-2">
                            <div className="service-detail">
                                <div className="row">
                                    <div className="images-box col-md-6 col-12">
                                        <figure className="image wow fadeIn"><a href="/#" className="lightbox-image" data-fancybox="services"><img src={department?.img} alt="" /></a></figure>
                                    </div>
                                    <div className="col-md-6 col-12 content-box">
                                        <div className="title-box">
                                            <h2>{department?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]}</h2>
                                            {/* <span className="theme_color">ResoFus Alomar Treatment for Essential Tremor and Parkinson's Disease</span> */}
                                        </div>
                                        {department?.[I18nManager.isRTL() ? "descriptionAr" : "descriptionEn"].map((item, index) => (
                                            <p key={index}>{item}</p>
                                        ))}
                                        <div className="two-column">
                                            <div className="row">
                                                <div className="image-column col-xl-6 col-lg-12 col-md-12">
                                                    <figure className="image"><a href="images/resource/post-img.jpg" className="lightbox-image"><img src="images/resource/post-img.jpg" alt="" /></a></figure>
                                                </div>
                                            </div>
                                        </div>
                                        {department?.list?.length > 0 &&
                                            <div className="two-column">
                                                <div className="row">
                                                    <div className="text-column col-12">
                                                        <ul className="list-style-one">
                                                            {department?.list?.map((el, i) => (
                                                                <Fragment key={i}>
                                                                    <li>{el?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]}</li>
                                                                    {el?.[I18nManager.isRTL() ? "descriptionAr" : "descriptionEn"].map((element, pIndex) => (
                                                                        <p key={pIndex}>{element}</p>
                                                                    ))}
                                                                </Fragment>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className='col-md-6 col-12'>
                                        <div className="outer-box">
                                            <a href="tel:966556189216" id="appointment-btn" className="theme-btn btn-style-one"><span className="btn-title"><i className={"flaticon-phone-1 " + (I18nManager.isRTL() ? "ml-1" : "mr-1")}></i>{I18nManager.isRTL() ? "اتصل الحين" : "Call now"}</span></a>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <FeatureBackView index={'dep-' + id} item={department} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </ViewContainer>
    )
}

export default DepartmentDetails
