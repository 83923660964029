import React, { useState } from 'react'
import './FeatureBackView.css'
import { sendFeatureEmail } from '../../../core/api/Api'
import { checkFormErrors, fullNameValidation } from '../../../core/common-service/CommonService'
import I18nManager from '../../../core/I18nManager/I18nManager'
import InvalidInputMsg from '../../_common/invalid-input-msg/InvalidInputMsg'

const FeatureBackView = ({ index, item }) => {

    const [FullName, setFullName] = useState('')
    const [MobileNo, setMobileNo] = useState('')
    const [formErrors, setFormErrors] = useState({
        FullName: '',
        MobileNo: '',
    })
    const [loading, setLoading] = useState(false)
    const [isSent, setIsSent] = useState(false)

    const checkFormValues = () => {
        setFormErrors({
            ...formErrors,
            FullName: fullNameValidation(FullName),
            MobileNo: MobileNo.length >= 6 ? true : false,
        })
    }

    const onInputChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        switch (name) {
            case 'FullName':
                setFormErrors({ ...formErrors, FullName: fullNameValidation(value) })
                setFullName(value)
                break;
            case 'MobileNo':
                setFormErrors({ ...formErrors, MobileNo: value.length >= 6 ? true : false })
                setMobileNo(value)
                break;
            default:
                break;
        }
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (!loading && checkFormErrors(formErrors)) {
            setLoading(true)
            sendFeatureEmail({ name: FullName, mobile: MobileNo, subject: item[I18nManager.isRTL() ? "titleAr" : "titleEn"] }).then(res => {
                setLoading(false)
                document.getElementById('FeatureFullName' + index).value = ''
                document.getElementById('FeatureMobileNo' + index).value = ''
                setIsSent(true)
                setTimeout(() => {
                    setFullName(''); setMobileNo('');
                    setIsSent(false)
                }, 3000);
            }).catch(e => { setLoading(false) })
        } else {
            checkFormValues()
        }
    }

    return (
        <div className="form-box feature-back-view">
            <div className="contact-form">
                <form>
                    <div className="row justify-content-center">
                        {isSent &&
                            <div className="success-message mt-2">
                                <p>{I18nManager.isRTL() ? (`شكرًا ` + (FullName)) : ("Thank you " + FullName)}</p>
                                <p className="mt-1 mb-1">{I18nManager.isRTL() ? `تمت عملية الحجز بنجاح، وسوف يتواصل معك فريق الحجوزات في أقرب وقت.` :
                                    `Your reservation has been successfully completed, and our reservations team will contact you as soon as possible.`}</p>
                                <p>{I18nManager.isRTL() ? "العودة للموقع" : "Back to the site"}</p>
                            </div>}
                        {!isSent && <>
                            <h4 className="text-center" style={{ marginBottom: 30 }}><a href="/#" onClick={e => e.preventDefault()}>{item?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]}</a></h4>
                            <div className="col-12">
                                <div className="form-group">
                                    <input type="text" name="FullName" id={'FeatureFullName' + index} onChange={onInputChange} className="email" placeholder={I18nManager.isRTL() ? "يرجى إدخال اسمك" : "Please enter your name"} />
                                    <InvalidInputMsg input={formErrors.FullName} name='FullName' />
                                </div>
                                <div className="form-group">
                                    <input type="tel" name="MobileNo" id={"FeatureMobileNo" + index} onChange={onInputChange} className="username" placeholder={I18nManager.isRTL() ? "رقم الهاتف" : "Telephone number"} />
                                    <InvalidInputMsg input={formErrors.MobileNo} name='MobileNo' />
                                </div>
                            </div>
                            <div className="pricing-block col-lg-12 pt-3 d-flex flex-column justify-content-center">
                                <div className="btn-box">
                                    <a href="/#" onClick={onSubmit} className="theme-btn">{I18nManager.isRTL() ? "أرسال" : "Send"}</a>
                                </div>
                            </div>
                        </>}
                    </div>
                </form>
            </div>
        </div>
    )
}
export default FeatureBackView