import axios from 'axios';

export const sendFeatureEmail = (params) => {
    const instance = axios.create({
        baseURL: 'https://fbmc.docnow247.com/',
        params: { ...params, endpoint: 'feature' }
    })
    return instance.get('mail-sender/index.php')
}

export const sendContactUsEmail = (params) => {
    const instance = axios.create({
        baseURL: 'https://fbmc.docnow247.com/',
        params: { ...params, endpoint: 'contactUs' }
    })
    return instance.get('mail-sender/index.php')
}

export const sendApplyJobEmail = (params, data) => {
    const instance = axios.create({
        baseURL: 'https://fbmc.docnow247.com/',
        params: { ...params, endpoint: 'applyJob' }
    })
    return instance.post('mail-sender/index.php', data)
}