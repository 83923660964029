import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { getCookie } from './core/common-service/CommonService';
import I18nManager from './core/I18nManager/I18nManager';

const dir = getCookie('dir')
if (dir === 'rtl' || dir === 'ltr') {
  I18nManager.setDirValue(dir === 'rtl' ? true : false)
  document.getElementsByTagName('html')[0].setAttribute("dir", dir);
  document.getElementsByTagName('html')[0].setAttribute("lang", dir === 'rtl' ? 'ar' : 'en');
} else {
  I18nManager.setDirValue(true)
  document.getElementsByTagName('html')[0].setAttribute("dir", 'rtl');
  document.getElementsByTagName('html')[0].setAttribute("lang", 'ar');
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
