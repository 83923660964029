import React from 'react'
import { constants } from '../../core/constants/constants'
import images from '../../core/constants/images'
import I18nManager from '../../core/I18nManager/I18nManager'
import ViewContainer from '../../core/routes/view-container/ViewContainer'

const AboutUs = () => {

    const data = [
        {
            titleEn: `An ambition came true:`, titleAr: `تحقّق الطموح:`,
            descriptionEn: `Building a comprehensive and integrated medical complex to serve the Saudi community and beyond has always been a long-term goal for us. Our ambition at Dr Fahed Bafaqih Medical Complex is to be a health care beacon for every member of the society and to be there at every step of the way to provide health care from ideas to applications shedding light on the Kingdom’s position as an international leader in the health care field.`,
            descriptionAr: `لطالما كان بناء مجمع طبي شامل ومتكامل لخدمة المجتمع السعودي وما وراءه هدفًا طويل الأمد لنا. فطموحنا في مجمع الدكتور فهد بافقيه الطبي أن نكون منارة للرعاية الصحية لكل فرد من أفراد المجتمع، وأن نكون هناك في كل خطوة على طريق تقديم الرعاية الصحية من الفكرة إلى التطبيق، وتسليط الضوء على مكانة المملكة العربية السعودية التي تستحقها كدولة رائدة عالمياً في مجال الرعاية الصحية.`
        },
        {
            titleEn: `What do we offer?`, titleAr: `ماذا نقدم؟`,
            descriptionEn: `At Dr Fahed Bafaqih Medical Complex in Riyadh, we offer you distinguished and qualitative medical services, led by distinguished and certified doctors with their long experience striving to be there for you around the clock.`,
            descriptionAr: `نقدم لكم في مجمع الدكتور فهد بافقيه الطبي بالرياض خدمات طبية متكاملة، حتى يتاح لكل شخص فرصة الحصول على خدمات طبية مميزة ونوعية، بقيادة أطباء ودكاترة معتمدين متميزين بخبراتهم الطويلة والذين يسعون دائماً لخدمتكم على مدار الساعة`
        },
        {
            descriptionEn: `The Dr Fahed Bafaqih Integrated Medical Complex in Riyadh has been developed to provide you with world-class treatment facilities and medical clinics for all patients. With more than 20 years of distinguished training and experience, we have provided you with the best-experienced surgeons and doctors from across the kingdom.`,
            descriptionAr: `هذا و قد تم تطوير مجمع الدكتور فهد بافقيه الطبي المتكامل بالرياض ليوفر لكم مرافق علاج  وعيادات طبية ذات مستوى عالمي لكل المرضى. مع أكثر من 20 عامًا من التدريب والخبرة المتميزة، ووفرنا لكم جراحين وأطباء أكفاء وخبراء على المستوى العالمي في المملكة العربية السعودية`
        },
    ]

    return (
        <ViewContainer>
            <section className="page-title" style={{ backgroundImage: `url(${images.headerAboutUsAr})` }}>
                <div className="auto-container">
                    <div className="title-outer">
                        <h1>{I18nManager.isRTL() ? "معلوماتنا" : "About Us"}</h1>
                        <ul className="page-breadcrumb">
                            <li><a href="index.html">{I18nManager.isRTL() ? "الصفحة الرئيسية" : "Home"}</a></li>
                            <li>{I18nManager.isRTL() ? "معلوماتنا" : "About"}</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="about-section">
                <div className="auto-container">
                    <div className="row">
                        <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                            <div className="inner-column">
                                <div className="sec-title">
                                    <span className="sub-title">{I18nManager.isRTL() ? "معلوماتنا" : "About Us"}</span>
                                    <h2>{I18nManager.isRTL() ? "نحن نضع معايير في البحث ، والأكثر من ذلك ، الرعاية في العيادة" : "We're setting Standards in Research what's more, Clinical Care."}</h2>
                                    <span className="divider"></span>
                                    {data.map((item, index) => (
                                        <div key={index}>
                                            {item[I18nManager.isRTL() ? "titleAr" : "titleEn"] && <p><b>{item[I18nManager.isRTL() ? "titleAr" : "titleEn"]}</b></p>}
                                            {item[I18nManager.isRTL() ? "descriptionAr" : "descriptionEn"] && <p style={{ marginTop: 0 }}>{item[I18nManager.isRTL() ? "descriptionAr" : "descriptionEn"]}</p>}
                                        </div>
                                    ))}
                                </div>
                                <div className="link-box">
                                    <figure className="signature"><img src={images.signatureFahad} alt="" /></figure>
                                    <a href="/#" className="theme-btn btn-style-one"><span className="btn-title">More About</span></a>
                                </div>
                            </div>
                        </div>

                        <div className="images-column col-lg-6 col-md-12 col-sm-12">
                            <div className="inner-column">
                                <div className="video-link">
                                    <a href="https://www.youtube.com/watch?v=4UvS3k8D4rs" className="play-btn lightbox-image" data-fancybox="images"><span className="flaticon-play-button-1"></span></a>
                                </div>
                                <figure className="image-1"><img src={images.aboutUs3} alt="" /></figure>
                                <figure className="image-2"><img src={images.aboutUs2} alt="" /></figure>
                                <figure className="image-3">
                                    <span className="hex"></span>
                                    <img src={images.aboutUs1} alt="" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pricing-section">
                <div className="auto-container">
                    <div className="sec-title text-center">
                        <span className="sub-title">{I18nManager.isRTL() ? "الباقات" : "Bundles"}</span>
                        <h2>{I18nManager.isRTL() ? "تفاصيل الباقات" : "Bundles Details"}</h2>
                        <span className="divider"></span>
                    </div>

                    <div className="outer-box">
                        <div className="row">
                            {constants.bundles.map((item, index) => (
                                <div key={index} className="pricing-block col-lg-3 col-md-6 col-sm-12">
                                    <div className="inner-box">
                                        <div>
                                            <div className="price-box">
                                                <h4 className="price">{item?.price} {I18nManager.isRTL() ? "ريال" : "SAR"}</h4>
                                                {/* <div className="validaty">Per Month</div> */}
                                            </div>
                                            <h3 className="title">{item?.[I18nManager.isRTL() ? "bundleNameAr" : "bundleNameEn"]}</h3>
                                            <ul className="features">
                                                {item?.[I18nManager.isRTL() ? "bundleDetailsAr" : "bundleDetailsEn"]?.map((el, index) => (
                                                    <li key={index}>{el}</li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className="btn-box">
                                            <a href="/#" className="theme-btn">{I18nManager.isRTL() ? "احجز الحين" : "Get the bundle"}</a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

        </ViewContainer>
    )
}

export default AboutUs