import React, { useEffect } from 'react';
import { goToWhatsapp } from '../../../core/common-service/CommonService';
import images from '../../../core/constants/images';
import I18nManager from '../../../core/I18nManager/I18nManager';
import ReactModal from '../react-modal/ReactModal';

const HomePopup = () => {
    useEffect(() => {
        setTimeout(() => {
            const el = document.getElementById('homePopupModalButton')
            el.click()
        }, 2000);
        return () => {
        }
    }, [])
    return (
        <>
            <button style={{ display: 'none' }} id="homePopupModalButton" type="button" data-toggle="modal" data-target="#homePopupModal" />
            <ReactModal id="homePopupModal" body={
                <div className="p-4">
                    <button type="button" data-dismiss="modal" aria-label="Close" style={{ position: 'absolute', right: 40, top: 20 }}>
                        <i className="fas fa-times"></i>
                    </button>
                    <div className="d-flex mb-2 mt-2">
                        <a href="tel:966556189216">
                            <img className="mx-auto" src={images.initialModalPopup} alt="fbmc logo" title="fbmc logo" height="120" />
                        </a>
                    </div>
                    <a href="tel:966556189216">
                        <h2 style={{ color: '#66C18F', textAlign: I18nManager.isRTL() ? "right" : "left", marginBottom: 10 }}>{I18nManager.isRTL() ? "خدمة الرد السريع" : "Quick reply service"}</h2>
                    </a>
                    <p style={{ textAlign: I18nManager.isRTL() ? "right" : "left" }}>{I18nManager.isRTL() ? "فريق خدمة العملاء بانتظار اتصالك، لتقديم المساعدة في أقل من دقيقة" : "Our customer service team is waiting for your call, to provide assistance in less than a minute"}</p>
                    <div className="pricing-block mt-4 mb-0">
                        <div className="inner-box" style={{ maxWidth: '100%', padding: 20 }}>
                            <div>
                                <ul className="features" style={{ maxWidth: '100%' }}>
                                    <li style={{ textAlign: I18nManager.isRTL() ? "right" : "left" }}>{I18nManager.isRTL() ? "حجز المواعيد ومتابعتها بكل سهولة" : "Book appointments and follow up with ease"}</li>
                                    <li style={{ textAlign: I18nManager.isRTL() ? "right" : "left" }}>{I18nManager.isRTL() ? "الحصول على أسعار وعروض خاصة" : "Get special rates and offers"}</li>
                                    <li style={{ textAlign: I18nManager.isRTL() ? "right" : "left" }}>{I18nManager.isRTL() ? "استقبال الملاحظات و الفحوصات" : "Receive tests and notes"}</li>
                                </ul>
                            </div>
                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                                <div className="outer-box">
                                    <a href="tel:966556189216" className="theme-btn btn-style-one">
                                        <span className="btn-title">
                                            <i className={"flaticon-phone-1 " + (I18nManager.isRTL() ? "ml-1" : "mr-1")}></i>
                                            {I18nManager.isRTL() ? "اتصل الحين" : "Call Now"}</span>
                                    </a>
                                </div>
                                &nbsp;
                                &nbsp;
                                <div className="outer-box">
                                    <a href={goToWhatsapp()} target="_blank" rel="noreferrer" className="theme-btn btn-style-one">
                                        <span className="btn-title">
                                            <i className={"fab fa-whatsapp " + (I18nManager.isRTL() ? "ml-1" : "mr-1")}></i>
                                            {I18nManager.isRTL() ? "رسالة واتساب" : "Whatsapp"}
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            } />
        </>
    )
}

export default HomePopup