import React from 'react'
import I18nManager from '../../core/I18nManager/I18nManager'
import ViewContainer from '../../core/routes/view-container/ViewContainer'
import { useHistory } from 'react-router-dom';

const NotFound = () => {
    let history = useHistory()

    const goToPage = (path) => (e) => {
        e.preventDefault()
        history.push(path)
    }

    return (
        <ViewContainer>
            <section className="page-title" style={{ backgroundImage: `url(images/background/8.jpg)` }}>
                <div className="auto-container">
                    <div className="title-outer">
                        <h1>Error Page</h1>
                        <ul className="page-breadcrumb">
                            <li><a href="index.html">Home</a></li>
                            <li>Error Page</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="error-section">
                <div className="auto-container">
                    <div className="content-box">
                        <figure className="error-image"><img src="images/icons/error.png" alt="" /></figure>
                        <h2>{I18nManager.isRTL() ? "الصفحة غير موجودة" : "Page not found"}</h2>
                        <div className="text">{I18nManager.isRTL() ? "الرجاء تجربة إحدى الصفحات التالية:" : "Please try one of the following pages:"}</div>
                        <a href="/home" onClick={goToPage('/home')} className="theme-btn btn-style-one"><span className="btn-title">{I18nManager.isRTL() ? "الصفحة الرئيسية" : "Home Page"}</span></a>
                        <a href="/contact-us" onClick={goToPage('/contact-us')} className="theme-btn btn-style-one"><span className="btn-title">{I18nManager.isRTL() ? "تواصل معنا" : "Contact Us"}</span></a>
                    </div>
                </div>
            </section>
        </ViewContainer>
    )
}
export default NotFound
